<app-header></app-header>
<div class="jumbotron text-center">
    <h1 class="mb-5"><b>Communication Hub</b></h1>
</div>
<div class="container my-4">
    <ul class="nav nav-underline" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="nav-tickets-tab" data-bs-toggle="tab" data-bs-target="#nav-tickets" type="button" role="tab" aria-controls="nav-tickets" aria-selected="true">Tickets</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" (click)="onChangeTab('projects')" id="nav-project-tab" data-bs-toggle="tab" data-bs-target="#nav-project" type="button" role="tab" aria-controls="nav-project" aria-selected="false">Project Management</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" (click)="onChangeTab('cr')" id="nav-cr-tab" data-bs-toggle="tab" data-bs-target="#nav-cr" type="button" role="tab" aria-controls="nav-cr" aria-selected="false">Change Requests</button>
        </li>
    </ul>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-tickets" role="tabpanel" aria-labelledby="nav-tickets-tab">
            <div class="row">
                <div class="col-12 text-center my-5">
                    <button class="btn btn-primary rounded-0 avsight-btn">Ask a Question</button>
                    <button class="btn btn-primary ms-5 rounded-0 avsight-btn" (click)="showCreateTicket()">Submit Ticket</button>
                </div>
            </div>
            <div class="list-group list-group-flush">
                <div class="list-group-item">
                    <div class="row">
                        <div class="col-4">
                            <h5 class="mb-0 mt-2">All Tickets</h5>
                        </div>
                        <div class="col-8" *ngIf="pagination.tickets.totalPage > 1">
                            <div class='text-end'>
                                <button class="btn btn-primary avsight-btn shrink" (click)="changeTicketPage(this.pagination.tickets.pageNo - 1)" [disabled]="pagination.tickets.pageNo == 1">Previous</button>
                                {{pagination.tickets.pageNo}}&nbsp;/&nbsp;{{pagination.tickets.totalPage}}
                                <button class="btn btn-primary avsight-btn shrink" (click)="changeTicketPage(this.pagination.tickets.pageNo + 1)" [disabled]="pagination.tickets.pageNo >= pagination.tickets.totalPage">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-group-item">
                    <div class="table-responsive" *ngIf="ticketList && ticketList.length > 0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Ticket No</th>
                                    <th scope="col">Contact Name</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Priority</th>
                                    <th scope="col">Date/Time Opened</th>
                                    <th scope="col">Date/Time Closed</th>
                                    <th scope="col">Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ticket of ticketList; let i = index">
                                    <td>{{((pagination.tickets.pageNo - 1) * pagination.pageSize) + i + 1}}</td>
                                    <td>{{ticket.CaseNumber}}</td>
                                    <td>{{ticket['Contact.Name']}}</td>
                                    <td>{{ticket.Subject}}</td>
                                    <td>{{ticket.Status}}</td>
                                    <td>{{ticket.Priority}}</td>
                                    <td>{{formatDate(ticket.CreatedDate, 'MM/DD/YYYY hh:mm:ss A')}}</td>
                                    <td>{{ticket.ClosedDate ? formatDate(ticket.ClosedDate, 'MM/DD/YYYY hh:mm:ss A') : ''}}</td>
                                    <td>{{ticket.Type}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade show" id="nav-project" role="tabpanel" aria-labelledby="nav-project-tab">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <select class="form-select" [(ngModel)]="projectTabData.selProject" (change)="onProjectChange()">
                                <option selected value="">--Select Project--</option>
                                <option *ngFor="let proj of projectTabData.projectList" value="{{proj.Id}}">{{proj.Name}}</option>
                            </select>
                        </div>
                        <div class="col-12 mt-3">
                            <ul class="nav nav-underline" role="tablist">
                                <li class="nav-item" role="presentation" (click)="onProjectChange()">
                                  <button class="nav-link active" id="nav-kanban-tab" data-bs-toggle="tab" data-bs-target="#nav-kanban" type="button" role="tab" aria-controls="nav-kanban" aria-selected="false">Kanban</button>
                                </li>
                                <li class="nav-item" role="presentation" (click)="onProjectChange()">
                                  <button class="nav-link" id="nav-report-tab" data-bs-toggle="tab" data-bs-target="#nav-report" type="button" role="tab" aria-controls="nav-report" aria-selected="true">Report</button>
                                </li>
                                <li class="nav-item" role="presentation" (click)="onProjectChange()">
                                  <button class="nav-link" id="nav-overview-tab" data-bs-toggle="tab" data-bs-target="#nav-overview" type="button" role="tab" aria-controls="nav-overview" aria-selected="false">Overview</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="nav-subTabContent">
                                <div class="tab-pane fade show active" id="nav-kanban" role="tabpanel" aria-labelledby="nav-kanban-tab">
                                    <div class="kanban-view-legend">
                                        <div class="float-start due-filter">
                                            <div class="float-start">
                                                <select class="form-select float-start" [(ngModel)]="projectTabData.project.dueFilter" (change)="getKanbanData()">
                                                    <option value="">All Due Dates</option>
                                                    <option value="today">Due Today</option>
                                                    <option value="thisweek">Due This Week</option>
                                                </select>
                                            </div>
                                            <span *ngIf="projectTabData.selProject">
                                                <button (click)="getChangeRequests()" class='cust-btn float-start'>Change Requests</button>
                                                <!-- <button onclick="{!c.openNewTaskModel}" class='cust-btn float-start'>New Task</button> -->
                                                <button (click)="showNewIssueModal()" class="cust-btn float-start">New Issue</button>
                                                <span class="d-inline-block mt-2 ms-2">
                                                    <input class="form-check-input" type="checkbox" value="" id="showMyIssues" (change)="getKanbanData()">
                                                    <label class="form-check-label ps-1" for="showMyIssues">
                                                        Show Only My Issues
                                                    </label>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="float-end pt-2" *ngIf="projectTabData.selProjectData && projectTabData.selProjectData.Id">
                                            <span title="License Expiring"><i class="{{'fa-solid fa-triangle-exclamation ' + (projectTabData.selProjectData.License_Expiring__c ? 'text-danger' : 'text-success')}}"></i></span>
                                            <span class="ms-1" title="Payment Late"><i class="{{'fa-regular fa-square-caret-down ' + (projectTabData.selProjectData.License_Expiring__c ? 'text-danger' : 'text-success')}}"></i></span>
                                            <span class="ms-1" title="Project Late"><i class="{{'fa-solid fa-circle-info ' + (projectTabData.selProjectData.Late__c ? 'text-danger' : 'text-success')}}"></i></span>
                                            <span class="ms-1" title="Project Stalled"><i class="{{'fa-solid fa-circle-minus ' + (projectTabData.selProjectData.Stalled__c ? 'text-danger' : 'text-success')}}"></i></span>
                                            <div class="d-inline ms-1 project-task"><span></span>Project Task</div>
                                            <div class="d-inline ms-1 me-2 project-issue"><span></span>Project Issue</div>
                                        </div>
                                    </div>
                                    <div id="kanbanBoard"></div>
                                </div>
                                <div class="tab-pane fade show" id="nav-report" role="tabpanel" aria-labelledby="nav-report-tab">
                                    <div class="mt-3 ">
                                        <h6 class="ms-1 mb-0 pb-2 border-bottom">Project Tasks completed this week</h6>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50%" scope="col" class="text-black-50">Name</th>
                                                        <th scope="col" class="text-black-50">End Date</th>
                                                        <th scope="col" class="text-black-50">Status</th>
                                                        <th scope="col" class="text-black-50">Related To</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="projectTabData.taskList.thisWeekCompletedTaskList && projectTabData.taskList.thisWeekCompletedTaskList.length > 0">
                                                    <tr *ngFor="let task of projectTabData.taskList.thisWeekCompletedTaskList">
                                                        <td>{{task.Name}}</td>
                                                        <td>{{formatDate(task.AcctSeed__End_Date__c, 'MMM DD, YYYY')}}</td>
                                                        <td>{{task.AcctSeed__Status__c}}</td>
                                                        <td>{{task['AcctSeed__Project__r.Name']}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3 ">
                                        <h6 class="ms-1 mb-0 pb-2 border-bottom">Project Tasks due next week</h6>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50%" scope="col" class="text-black-50">Name</th>
                                                        <th scope="col" class="text-black-50">End Date</th>
                                                        <th scope="col" class="text-black-50">Status</th>
                                                        <th scope="col" class="text-black-50">Related To</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="projectTabData.taskList.nextWeekDueTaskList && projectTabData.taskList.nextWeekDueTaskList.length > 0">
                                                    <tr *ngFor="let task of projectTabData.taskList.nextWeekDueTaskList">
                                                        <td>{{task.Name}}</td>
                                                        <td>{{formatDate(task.AcctSeed__End_Date__c, 'MMM DD, YYYY')}}</td>
                                                        <td>{{task.AcctSeed__Status__c}}</td>
                                                        <td>{{task['AcctSeed__Project__r.Name']}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3 ">
                                        <h6 class="ms-1 mb-0 pb-2 border-bottom">Project Tasks overdue</h6>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th width="50%" scope="col" class="text-black-50">Name</th>
                                                        <th scope="col" class="text-black-50">End Date</th>
                                                        <th scope="col" class="text-black-50">Status</th>
                                                        <th scope="col" class="text-black-50">Related To</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="projectTabData.taskList.overDueTaskList && projectTabData.taskList.overDueTaskList.length > 0">
                                                    <tr *ngFor="let task of projectTabData.taskList.overDueTaskList">
                                                        <td>{{task.Name}}</td>
                                                        <td>{{formatDate(task.AcctSeed__End_Date__c, 'MMM DD, YYYY')}}</td>
                                                        <td>{{task.AcctSeed__Status__c}}</td>
                                                        <td>{{task['AcctSeed__Project__r.Name']}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade show" id="nav-overview" role="tabpanel" aria-labelledby="nav-overview-tab">
                                    <div class="gantt_control" *ngIf="projectTabData.selProject">
                                        <button (click)="onZoomToFit()" class='float-end'>{{projectTabData.overview.ganttConfig ? projectTabData.overview.ganttConfig.zoomToFitLabel : ''}}</button>
                                        <button (click)="onZoomOut()" class='float-end'><i class="fa-solid fa-magnifying-glass-minus"></i></button>
                                        <button (click)="onZoomIn()" class='float-end'><i class="fa-solid fa-magnifying-glass-plus"></i></button>
                                    </div>
                                    <div id="task_grid_gantt" class="w-100" style="height:50vh;"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade show" id="nav-cr" role="tabpanel" aria-labelledby="nav-cr-tab">
            <div class="list-group list-group-flush">
                <div class="list-group-item border-0 mt-2" *ngIf="pagination.cr.totalPage > 1">
                    <div class="row">
                        <div class="col-12">
                            <div class='text-end'>
                                <button class="btn btn-primary avsight-btn shrink" (click)="changeCRPage(this.pagination.cr.pageNo - 1)" [disabled]="pagination.cr.pageNo == 1">Previous</button>
                                {{pagination.cr.pageNo}}&nbsp;/&nbsp;{{pagination.cr.totalPage}}
                                <button class="btn btn-primary avsight-btn shrink" (click)="changeCRPage(this.pagination.cr.pageNo + 1)" [disabled]="pagination.cr.pageNo >= pagination.cr.totalPage">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-group-item mt-2">
                    <div class="table-responsive" *ngIf="crList && crList.length > 0">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">CR ID</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Request Status</th>
                                    <th scope="col">Urgency</th>
                                    <th scope="col">Requested Date</th>
                                    <th scope="col">Functionality</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cr of crList; let i = index">
                                    <td>{{((pagination.cr.pageNo - 1) * pagination.pageSize) + i + 1}}</td>
                                    <td><a class="text-decoration-none" href="/change-request/{{cr.Id}}" target="_blank">{{cr.Name}}</a></td>
                                    <td>{{cr.Change_Request_Summary__c}}</td>
                                    <td>{{cr.Status__c}}</td>
                                    <td>{{cr.Urgency__c}}</td>
                                    <td>{{formatDate(cr.Requested_Date__c, 'MM/DD/YYYY')}}</td>
                                    <td>{{cr.Functionality__c}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- create ticket -->
<div class="modal fade" id="createTicketModal" role="dialog" aria-labelledby="createTicketModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createTicketModalLabel">Create New Ticket</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideCreateTicket()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="ticketForm" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Subject<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="Subject" name="Subject">
                                <label *ngIf="loadingButtons.createTicketClicked && ticketForm.get('Subject')?.invalid"
                                    [ngClass]="'error'"> Subject is required. </label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Description<span class="text-danger">*</span></label>
                                <textarea type="text" class="form-control" formControlName="Description"
                                    name="Description"></textarea>
                                <label *ngIf="loadingButtons.createTicketClicked && ticketForm.get('Description')?.invalid"
                                    [ngClass]="'error'"> Description is required. </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideCreateTicket()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="createTicket()"
                    [attr.disabled]="loadingButtons.isCreatingTicket ? 'disabled' : null">
                    <span *ngIf="loadingButtons.isCreatingTicket">
                        <i class='spinner-border spinner-border-sm'></i> Saving...
                    </span>
                    <span *ngIf="!loadingButtons.isCreatingTicket">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- project change requests -->
<div class="modal fade" id="changeRequestModal" role="dialog" aria-labelledby="changeRequestModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="changeRequestModalLabel">Project Change Requests</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideChangeRequestModal()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">CRID</th>
                                <th scope="col">Request Summary</th>
                                <th scope="col">Status</th>
                                <th scope="col">Trello Board</th>
                                <th scope="col">Assigned To</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="projectTabData.crList && projectTabData.crList.length > 0">
                            <tr *ngFor="let cr of projectTabData.crList; let i = index">
                                <td><a class="text-decoration-none" href="/change-request/{{cr.Id}}" target="_blank">{{cr.Name}}</a></td>
                                <td>{{cr.Change_Request_Summary__c}}</td>
                                <td>{{cr.Status__c}}</td>
                                <td>{{cr.Trello_Board_Name__c}}</td>
                                <td>{{cr.Assigned_To__r ? cr.Assigned_To__r.Name : ''}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="hideChangeRequestModal()">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- create project issue -->
<div class="modal fade" id="createIssueModal" role="dialog" aria-labelledby="createIssueModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createIssueModalLabel">Create New Project Issue</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideCreateIssue()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="issueForm" novalidate>
                    <h5>Issue Details</h5>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Issue Title<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="Title" name="Title">
                                <label *ngIf="loadingButtons.createIssueClicked && issueForm.get('Title')?.invalid"
                                    [ngClass]="'error'"> Title is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Functional Area<span class="text-danger">*</span></label>
                                <select class="form-select" formControlName="FunctionalArea" name="FunctionalArea">
                                    <option value="">--None--</option>
                                    <option *ngFor="let op of projectTabData.issueData.functionalAreaList" value="{{op.value}}">{{op.label}}</option>
                                </select>
                                <label *ngIf="loadingButtons.createIssueClicked && issueForm.get('FunctionalArea')?.invalid"
                                    [ngClass]="'error'"> Functional Area is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Issue Type<span class="text-danger">*</span></label>
                                <select class="form-select" formControlName="IssueType" name="IssueType">
                                    <option value="">--None--</option>
                                    <option *ngFor="let op of projectTabData.issueData.issueTypeList" value="{{op.value}}">{{op.label}}</option>
                                </select>
                                <label *ngIf="loadingButtons.createIssueClicked && issueForm.get('IssueType')?.invalid"
                                    [ngClass]="'error'"> Issue Type Area is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Description</label>
                                <textarea type="text" class="form-control" formControlName="Description" name="Description"></textarea>
                            </div>
                        </div>
                    </div>

                    <h5>Issue Information</h5>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Work Starts</label>
                                <input type="date" class="form-control" formControlName="WorkStarts" name="WorkStarts">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Work Ends</label>
                                <input type="date" class="form-control" formControlName="WorkEnds" name="WorkEnds">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Priority</label>
                                <select class="form-select" formControlName="Priority" name="Priority">
                                    <option value="">--None--</option>
                                    <option *ngFor="let op of projectTabData.issueData.priorityList" value="{{op.value}}">{{op.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Reported By</label>
                                <input type="text" class="form-control" formControlName="ReportedBy" name="ReportedBy">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideCreateIssue()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="createProjectIssue()"
                    [attr.disabled]="loadingButtons.isCreatingIssue ? 'disabled' : null">
                    <span *ngIf="loadingButtons.isCreatingIssue">
                        <i class='spinner-border spinner-border-sm'></i> Saving...
                    </span>
                    <span *ngIf="!loadingButtons.isCreatingIssue">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>