<app-header></app-header>
<div class="jumbotron text-center">
    <h1 class="mb-5"><b>Feature Requests</b></h1>
</div>
<div class="container mt-4">
    <div class="row">
        <div class="col-md-9">
            <h4>Sign up to test our latest beta features</h4>
        </div>
        <div class="col-md-3 text-end">
            <button class="btn btn-primary avsight-btn">Get Started</button>
        </div>
    </div>
    <hr />
</div>
<div class="container my-4">
    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-3">
                    <select class="form-select" [(ngModel)]="filterParam.selectedCategory" (change)="onFilterChange()">
                        <option selected value="">Filter by Category</option>
                        <option *ngFor="let cat of featureReqData.featurePointsCategoryList" value="{{cat.key}}">{{cat.label}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <select class="form-select" [(ngModel)]="filterParam.sortType" (change)="onFilterChange()">
                        <option value="Popular" selected>Showing Popular</option>
                        <option value="Recent">Showing Recent</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <select class="form-select" [(ngModel)]="filterParam.seletedStatus" (change)="onFilterChange()">
                        <option selected value="">All Status</option>
                        <option *ngFor="let st of featureReqData.featurePointsStatusList" value="{{st.key}}">{{st.label}}</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <span (keypress)="checkForEnter($event)">
                        <input type="email" class="form-control" placeholder="Search" [(ngModel)]="filterParam.searchText">
                    </span>
                </div>
                <div class="col-md-3 mt-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="showMyReq" [(ngModel)]="filterParam.showMyReq" (change)="onFilterChange()">
                        <label class="form-check-label" for="showMyReq">
                            Show My Requests
                        </label>
                    </div>
                </div>
                <div class="col-md-9 mt-3" *ngIf="pagination.totalPage > 1">
                    <div class='text-center'>
                        <button class="btn btn-primary avsight-btn shrink" (click)="changePage(this.pagination.pageNo - 1)" [disabled]="pagination.pageNo == 1">Previous</button>
                        {{pagination.pageNo}}&nbsp;/&nbsp;{{pagination.totalPage}}
                        <button class="btn btn-primary avsight-btn shrink" (click)="changePage(this.pagination.pageNo + 1)" [disabled]="pagination.pageNo >= pagination.totalPage">Next</button>
                    </div>
                </div>
            </div>
            <div *ngFor="let fItem of featureRequestList; let i = index" [attr.data-index]="i" class="feature-item mt-2 card" data-type='view' (click)="onFeatureItemClick($event)">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8 border-end">
                            <div class="idea-title">{{fItem.Name}}</div>
                            <div class="idea-desc">{{fItem.Feature_Description__c}}</div>
                        </div>
                        <div class="col-md-4">
                            <div class='item-status'>{{fItem.Status__c}}</div>
                            <div class="vote-container">
                                <span>{{fItem.Total_Points__c}} POINTS</span>
                                <div class='upvote-btn' data-type='vote'>UPVOTE</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="new-req-form">
                <h5 class="text-center">New Feature Request</h5>
                <div class="card row">
                    <div class="col-12">
                        <label>Remaining Upvotes</label>
                        <div>{{featureReqData.remainingFeaturePoints || '0'}}</div>
                    </div>
                </div>
                <form [formGroup]="newRequestForm" #requestForm="ngForm" novalidate>
                    <div class="row">
                        <div class="col-12 p-0 pt-2">
                            <label>Feature Request Name</label>
                            <input type="text" class="form-control" formControlName="Name" name="Name">
                            <label *ngIf="loadingButtons.saveClicked && newRequestForm.get('Name')?.invalid" [ngClass]="'error'"> Name is required. </label>
                        </div>
                        <div class="col-12 p-0 pt-2">
                            <label>Original Feature Description</label>
                            <textarea class="form-control" formControlName="Description" name="Description"></textarea>
                        </div>
                        <div class="col-12 p-0 pt-2">
                            <label title="Select the screen or functional area under which the feature would be built.">
                                Category <i class="fa-solid fa-circle-info fa-xs"></i>
                            </label>
                            <select class="form-select" multiple formControlName="Category" name="Category">
                                <option *ngFor="let cat of featureReqData.featurePointsCategoryList" value="{{cat.key}}">{{cat.label}}</option>
                            </select>
                        </div>
                        <div class="col-12 p-0 pt-3">
                            <button type="submit" class="btn btn-primary avsight-btn w-100" (click)="createFeatureRequest()"
                                [attr.disabled]="loadingButtons.isSaving ? 'disabled' : null">
                                <span *ngIf="loadingButtons.isSaving">
                                    <i class='spinner-border spinner-border-sm'></i> Saving...
                                </span>
                                <span *ngIf="!loadingButtons.isSaving">Save</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- feature request info -->
<div class="modal fade" id="featureReqDataModal" role="dialog" aria-labelledby="featureReqDataModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="featureReqDataModalLabel">Request Details</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideVoteDialog()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-text">Feature Request Name</label>
                            <div>{{voteObj.Name}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-text">Requested By</label>
                            <div>{{voteObj['Requested_By__r.Name']}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group pt-md-2">
                            <label class="form-text">Original Feature Description</label>
                            <div>{{voteObj.Feature__c}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group pt-md-2">
                            <label class="form-text">Updated Feature Description</label>
                            <div>{{voteObj.Feature_Description__c}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group pt-md-2">
                            <label class="form-text">Category</label>
                            <div>{{voteObj.Category__c}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group pt-md-2">
                            <label class="form-text">Total Request Points</label>
                            <div>{{voteObj.Total_Points__c}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                    (click)="hideVoteDialog()">Cancel</button>
            </div>
        </div>
    </div>
</div>