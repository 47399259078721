import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-knowledgebase',
  templateUrl: './knowledgebase.component.html',
  styleUrl: './knowledgebase.component.css'
})
export class KnowledgebaseComponent implements OnInit {

  ngOnInit(): void {
    $('#portal-page').on('load', function(){
        setTimeout(function() {
          $('#portal-page').removeClass("d-none");
        }, 1000);
    });
  }
}
