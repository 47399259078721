import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { HeaderComponent } from './components/header/header.component';
import { FeaturerequestsComponent } from './components/featurerequests/featurerequests.component';
import { CommunicationhubComponent } from './components/communicationhub/communicationhub.component';
import { UpdaterequestsComponent } from './components/updaterequests/updaterequests.component';
import { AccountComponent } from './components/account/account.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjecttaskComponent } from './components/projecttask/projecttask.component';
import { ProjectissueComponent } from './components/projectissue/projectissue.component';
import { ChangerequestComponent } from './components/changerequest/changerequest.component';
import { KnowledgebaseComponent } from './components/knowledgebase/knowledgebase.component';
import { CopilotComponent } from './components/copilot/copilot.component';
import { PreferencesComponent } from './components/preferences/preferences.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    HeaderComponent,
    FeaturerequestsComponent,
    CommunicationhubComponent,
    UpdaterequestsComponent,
    AccountComponent,
    ProjectComponent,
    ProjecttaskComponent,
    ProjectissueComponent,
    ChangerequestComponent,
    KnowledgebaseComponent,
    CopilotComponent,
    PreferencesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }