<div class="login-page">
    <div class="login-box" id="app-login" *ngIf="showPreferences == false">
        <div class="message-box">
            <div class="d-flex justify-content-center align-items-center">
                {{loginMessage}}
            </div>
            <div class="d-flex justify-content-center align-items-center mt-2">
                <button *ngIf="allowResend" type="button" (click)="onSendCodeClick('resend')" class="btn btn-primary btn-block" [attr.disabled]="loadingButtons.resend ? 'disabled' : null">
                    <span *ngIf="loadingButtons.resend">
                        <i class='spinner-border spinner-border-sm'></i> Sending...
                    </span>
                    <span *ngIf="!loadingButtons.resend">
                        Resend Access Code
                    </span>
                </button>
                <button *ngIf="allowReGenerate" type="button" (click)="onSendCodeClick('generate')" class="btn btn-primary btn-block" [attr.disabled]="loadingButtons.resend ? 'disabled' : null">
                    <span *ngIf="loadingButtons.resend">
                        <i class='spinner-border spinner-border-sm'></i> Sending...
                    </span>
                    <span *ngIf="!loadingButtons.resend">
                        Get New Access Code
                    </span>
                </button>
            </div>
        </div>
        <!-- <div class="login-form">
            <div class="login-logo text-center">
                <img src="../../../assets/images/logo.png" width="150" />
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSignIn()" novalidate>
                <div class="form-group mb-3">
                    <div class="input-group">
                        <input type="text" formControlName="username" name="username" class="form-control" placeholder="Username" required>
                        <label *ngIf="loadingButtons.clicked && loginForm.get('username')?.invalid" [ngClass] = "'error'"> Username is required. </label>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <div class="input-group">
                        <input type="password" formControlName="password" name="password" class="form-control" placeholder="Password" required>
                        <label *ngIf="loadingButtons.clicked && loginForm.get('password')?.invalid" [ngClass] = "'error'"> Password is required. </label>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-block" [attr.disabled]="loadingButtons.login ? 'disabled' : null">
                        <span *ngIf="loadingButtons.login">
                            <i class='spinner-border spinner-border-sm'></i> Signing In...
                        </span>
                        <span *ngIf="!loadingButtons.login">
                            Sign In
                        </span>
                    </button>
                </div>
            </form>
        </div> -->
    </div>
    <app-preferences *ngIf="showPreferences"  [preferenceData]="preferenceData"></app-preferences>
</div>