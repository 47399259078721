import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
declare var moment: any;
declare var commons: any;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrl: './project.component.css'
})
export class ProjectComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }
  projectData: any = {};

  ngOnInit(): void {
    const projId = this.route.snapshot.paramMap.get("id");
    if (!projId) {
      return;
    }
    commons.loader.show();
    this.getProjectDetails(projId);
  }

  getProjectDetails(projId: any) {
    var self = this;
    var param = {
      projectId: projId
    };
    commons.jqueryAjax(environment.BASE_URL + "project/details", { data: JSON.stringify(param)}, function (res: any) {
      commons.loader.hide();
      if (res.status == 1) {
        self.projectData = res.data;
      }
    });
  }

  formatDate(date: string, format: string) {
    return moment.utc(date).format(format);
  }
}
