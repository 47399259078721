<app-header></app-header>
<div class="container my-4">
    <div class="row">
        <div class="col-md-8">
            <h5>Change Request Details</h5>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Title</div>
                    <div class="border-bottom">{{crData.Change_Request_Summary__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Change Request Details</div>
                    <div class="border-bottom">{{crData.Change_Request_Details__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Functionality</div>
                    <div class="border-bottom">{{crData.Functionality__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Request Status</div>
                    <div class="border-bottom">{{crData.Status__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Contract Status</div>
                    <div class="border-bottom">{{crData.Contract_Status__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Assigned To</div>
                    <div class="border-bottom">{{crData['Assigned_To__r.Name']}}&nbsp;</div>
                </div>
            </div>

            <h5 class="mt-4">Change Request Information</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Change Request ID</div>
                    <div class="border-bottom">{{crData.Name}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Urgency</div>
                    <div class="border-bottom">{{crData.Urgency__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Account</div>
                    <div class="border-bottom">{{crData['Account__r.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Date Needed</div>
                    <div class="border-bottom">{{crData.Date_Needed__c ? formatDate(crData.Date_Needed__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Requested By</div>
                    <div class="border-bottom">{{crData.Requested_By__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Project</div>
                    <div class="border-bottom"><a class="text-decoration-none" href="/project-details/{{crData.Project__c}}">{{crData['Project__r.Name']}}</a>&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Request Source</div>
                    <div class="border-bottom">{{crData.Request_Source__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Status Info</div>
                    <div class="border-bottom">{{crData.Status_Info__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Requested Date</div>
                    <div class="border-bottom">{{crData.Requested_Date__c ? formatDate(crData.Requested_Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Exception</div>
                    <div class="border-bottom">{{crData.Exception__c}}&nbsp;</div>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>