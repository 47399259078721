<app-header></app-header>
<div class="jumbotron text-center">
    <h1 class="mb-5"><b>Request an Upgrade</b></h1>
</div>
<div class="container my-4">
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-primary rounded-0 avsight-btn" (click)="showCreateRequest()">Create Request</button>
        </div>
    </div>
    <hr />
    <div class="row" *ngIf="pagination.totalPage > 1">
        <div class="col-12">
            <div class='text-end'>
                <button class="btn btn-primary avsight-btn shrink" (click)="changeRequestPage(this.pagination.pageNo - 1)" [disabled]="pagination.pageNo == 1">Previous</button>
                {{pagination.pageNo}}&nbsp;/&nbsp;{{pagination.totalPage}}
                <button class="btn btn-primary avsight-btn shrink" (click)="changeRequestPage(this.pagination.pageNo + 1)" [disabled]="pagination.pageNo >= pagination.totalPage">Next</button>
            </div>
        </div>
    </div>
    <div class="table-responsive" *ngIf="requestList && requestList.length > 0">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Request Name</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Old Version</th>
                    <th scope="col">Target Version</th>
                    <th scope="col">Current Version</th>
                    <th scope="col">Current Version Number</th>
                    <th scope="col">Requested Date</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let req of requestList; let i = index">
                    <td>{{((pagination.pageNo - 1) * pagination.pageSize) + i + 1}}</td>
                    <td>{{req.Name}}</td>
                    <td>{{req['Account__r.Name']}}</td>
                    <td>{{req.Old_Version__c}}</td>
                    <td>{{req.Target_Version__c}}</td>
                    <td>{{req.Current_Version__c}}</td>
                    <td>{{req.Version_Number__c}}</td>
                    <td>{{formatDate(req.CreatedDate, 'MM/DD/YYYY')}}</td>
                    <td>{{req.Status__c}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- create update request -->
<div class="modal fade" id="createRequestModal" role="dialog" aria-labelledby="createRequestModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createRequestModalLabel">New Update Request</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideCreateRequest()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="requestForm" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">SB Requested Date</label>
                                <input type="date" class="form-control" formControlName="SBRequestedDate" name="SBRequestedDate">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">SB Requested Date</label>
                                <select class="form-select" formControlName="TargetVersion" name="TargetVersion">
                                    <option selected value="">--Select Version--</option>
                                    <option *ngFor="let ver of versionList" value="{{ver.key}}">{{ver.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Prod Requested Date</label>
                                <input type="date" class="form-control" formControlName="ProdRequestedDate" name="ProdRequestedDate">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Customer Comments</label>
                                <textarea type="text" class="form-control" formControlName="Comments" name="Comments"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideCreateRequest()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="createRequest()"
                    [attr.disabled]="loadingButtons.isCreatingRequest ? 'disabled' : null">
                    <span *ngIf="loadingButtons.isCreatingRequest">
                        <i class='spinner-border spinner-border-sm'></i> Saving...
                    </span>
                    <span *ngIf="!loadingButtons.isCreatingRequest">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>