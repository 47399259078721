<div class="m-4">
    <div class="row">
        <div class="col-md-12" *ngIf="preferenceData">
            <h5>Preferences</h5>
            <div class="row mt-4">
                <div class="col-md-3 mb-4">
                    <h6>Role</h6>
                    <div *ngFor="let role of preferenceData.roles; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="role" [checked]="preferenceData.selRoles && preferenceData.selRoles.indexOf(role) != -1" [id]="'role' + i">
                        <label class="form-check-label ps-1" [for]="'role' + i">
                            {{role}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Seniority</h6>
                    <div *ngFor="let seniority of preferenceData.seniorities; let i = index">
                        <input class="form-check-input" type="radio" name="seniority" [value]="seniority" [checked]="preferenceData.selSenioirty && preferenceData.selSenioirty == seniority" [id]="'seniority' + i">
                        <label class="form-check-label ps-1" [for]="'seniority' + i">
                            {{seniority}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Notification Preferences</h6>
                    <div *ngFor="let pref of preferenceData.notificationPreferences; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="pref" [checked]="preferenceData.selNotification && preferenceData.selNotification.indexOf(pref) != -1" [id]="'pref' + i">
                        <label class="form-check-label ps-1" [for]="'pref' + i">
                            {{pref}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Business Segments</h6>
                    <div *ngFor="let seg of preferenceData.businessSegments; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="seg" [checked]="preferenceData.selBusinessSegments && preferenceData.selBusinessSegments.indexOf(seg) != -1" [id]="'seg' + i">
                        <label class="form-check-label ps-1" [for]="'seg' + i">
                            {{seg}}
                        </label>
                    </div>
                </div>
                <div class="col-12 mb-4">
                    <h6>TPN Directory</h6>
                    <div>
                        <input class="form-check-input" type="checkbox" [value]="preferenceData.tpnDirectory" [checked]="preferenceData.tpnDirectory" id="tpnDirectory">
                        <label class="form-check-label ps-1" for="tpnDirectory">
                            Would you like to share your TPN status with other TPN businesses so they may request to be added as your partner?
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block"(click)="updatePreferences()" [attr.disabled]="loadingButtons.update ? 'disabled' : null">
                        <span *ngIf="loadingButtons.update">
                            <i class='spinner-border spinner-border-sm'></i> Updating...
                        </span>
                        <span *ngIf="!loadingButtons.update">
                            Update
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>