import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.css'
})
export class PreferencesComponent implements OnInit {
  @Input() preferenceData: any;

  loadingButtons: any = {
    update: false
  }

  ngOnInit(): void {
  }

  updatePreferences() {
    let roles: any = [], notificationPref: any = [], seniority: any = '', businessSegment: any = [], tpnDirectory: any = false;
    $("[id^='role']").each(function(this: any) {
      $(this).is(":checked") ? roles.push($(this).val()) : '';
    });
    seniority = $("[id^='seniority']:checked").val();
    $("[id^='pref']").each(function(this: any) {
      $(this).is(":checked") ? notificationPref.push($(this).val()) : '';
    });
    $("[id^='seg']").each(function(this: any) {
      $(this).is(":checked") ? businessSegment.push($(this).val()) : '';
    });
    tpnDirectory = $("#tpnDirectory").is(":checked");
    if (roles.length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select role to continue.",
        timeout: 3000
      });
      return;
    }
    if (!seniority || seniority.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select seniority to continue.",
        timeout: 3000
      });
      return;
    }
    if (notificationPref.length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please select notification preference to continue.",
        timeout: 3000
      });
      return;
    }
    var self = this;
    self.loadingButtons.update = true;
    let param: any = {
      role: roles.join(";"),
      seniority: seniority,
      notification: notificationPref.join(";"),
      businessSegment: businessSegment.join(";"),
      tpnDirectory: tpnDirectory
    }
    commons.jqueryAjax(environment.BASE_URL + "preferences/update", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        self.loadingButtons.update = false;
        commons.notification.show({
            type: "error",
            msg: res.message,
            timeout: 3000
        });
        return;
      }
      if (self.preferenceData.isFromAccount) {
        commons.notification.show({
          type: "success",
          msg: "Preferences updated successfully.",
          timeout: 3000
        });
        self.loadingButtons.update = false;
      } else {
        //commons.validateSession();
      }
    });
  }
}
