<app-header></app-header>
<div class="container my-4">
    <div class="row">
        <div class="col-md-8">
            <h5>Issue Details</h5>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Issue Title</div>
                    <div class="border-bottom">{{issueData.Issue_Title__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Description</div>
                    <div class="border-bottom">{{issueData.Description__c}}&nbsp;</div>
                </div>
            </div>

            <h5 class="mt-4">Issue Information</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Project Issue ID</div>
                    <div class="border-bottom">{{issueData.Name}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Issue Type</div>
                    <div class="border-bottom">{{issueData.Issue_Type__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Project</div>
                    <div class="border-bottom"><a class="text-decoration-none" href="/project-details/{{issueData.AS_Project__c}}">{{issueData['AS_Project__r.Name']}}</a>&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Status</div>
                    <div class="border-bottom">{{issueData.Status__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Work Starts</div>
                    <div class="border-bottom">{{issueData.Date__c ? formatDate(issueData.Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Work Ends</div>
                    <div class="border-bottom">{{issueData.Due_Date__c ? formatDate(issueData.Due_Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Responsibility</div>
                    <div class="border-bottom">{{issueData['Responsibility__r.Name']}}&nbsp;</div>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>