import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  get isEmbed() {
    var url = new URL(window.location.href);
    var embed = url.searchParams.get("embed");
    return (embed && embed == 'avsight') || window.location.href.indexOf('public/copilot') != -1;
  }

  ngOnInit(): void {
    var url_string = window.location.href;
    var url = new URL(url_string);
    if (url && url.pathname) {
      $('#navbarAvSight .nav-item .nav-link[href="' + url.pathname + '"]').addClass('active');
    }
  }

  logout() {
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "logout", {}, function(res: any) {
        window.location.reload();
    });
  }
}
