<!-- Create a navigation bar with links -->
<nav  *ngIf="!isEmbed" class="navbar navbar-expand-lg navbar-default bg-light">
    <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarAvSight"
            aria-controls="navbarAvSight" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa-solid fa-bars"></i>
        </button>
        <div class="order-3 collapse navbar-collapse" id="navbarAvSight">
            <ul class="nav navbar-nav">
                <li class="nav-item px-lg-3"><a class="nav-link" href="/">Home</a></li>
                <li class="nav-item px-lg-3"><a class="nav-link" href="/knowledgebase">Knowledge Base</a></li>
                <li class="nav-item px-lg-3"><a class="nav-link" href="https://support.avsight.net/avsight-university/" target="_blank">AvSight University</a></li>
                <li class="nav-item px-lg-3"><a class="nav-link" href="/communication-hub">Communication Hub</a></li>
                <li class="nav-item px-lg-3"><a class="nav-link" href="/my-account">Preferences</a></li>
                <li class="nav-item px-lg-3"><a class="nav-link" href="/update-requests">Request an Upgrade</a></li>
                <!-- <li class="nav-item px-lg-3"><a class="nav-link" href="/copilot">Copilot</a></li> -->
            </ul>
        </div>
        <ul class="order-1 order-lg-3 navbar-nav navbar-no-expand flex-row ml-auto">
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-bell"></i>
                </a>
            </li>
            <li class="nav-item btn-group">
                <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
                    <i class="fa-regular fa-circle-user"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li><a class="dropdown-item" href="/">Home</a></li>
                    <li><a class="dropdown-item" href="/profile">Profile</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a></li>
                </ul>
            </li>
        </ul>
    </div>
</nav>