import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FeaturerequestsComponent } from './components/featurerequests/featurerequests.component';
import { CommunicationhubComponent } from './components/communicationhub/communicationhub.component';
import { UpdaterequestsComponent } from './components/updaterequests/updaterequests.component';
import { AccountComponent } from './components/account/account.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjecttaskComponent } from './components/projecttask/projecttask.component';
import { ProjectissueComponent } from './components/projectissue/projectissue.component';
import { ChangerequestComponent } from './components/changerequest/changerequest.component';
import { KnowledgebaseComponent } from './components/knowledgebase/knowledgebase.component';
import { CopilotComponent } from './components/copilot/copilot.component';
import { PreferencesComponent } from './components/preferences/preferences.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'AvSight Support | Home' } },
  { path: 'login', component: LoginComponent, data: { title: 'AvSight Support | Sign In' } },
  { path: 'profile', component: ProfileComponent, data: { title: 'Profile' } },
  { path: 'feature-requests', component: FeaturerequestsComponent, data: { title: 'Feature Requests' } },
  { path: 'communication-hub', component: CommunicationhubComponent, data: { title: 'Communication Hub' } },
  { path: 'update-requests', component: UpdaterequestsComponent, data: { title: 'Request an Upgrade' } },
  { path: 'my-account', component: AccountComponent, data: { title: 'My Account' } },
  { path: 'project-details/:id', component: ProjectComponent, data: { title: 'Project Details' } },
  { path: 'task-details/:id', component: ProjecttaskComponent, data: { title: 'Project Task Details' } },
  { path: 'issue-details/:id', component: ProjectissueComponent, data: { title: 'Project Issue Details' } },
  { path: 'change-request/:id', component: ChangerequestComponent, data: { title: 'Change Request Details' } },
  { path: 'knowledgebase', component: KnowledgebaseComponent, data: { title: 'Knowledge Base' } },
  { path: 'copilot', component: CopilotComponent, data: { title: 'Copilot' } },
  { path: 'preferences', component: PreferencesComponent, data: { title: 'Preferences' } },
  { path: 'public/copilot', component: CopilotComponent, data: { title: 'Copilot' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }