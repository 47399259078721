<app-header></app-header>
<!-- Create a jumbotron with a header and a search bar -->
<div class="jumbotron text-center">
    <h1 class="mb-5"><b>AvSight Support</b></h1>
    <!--  <form class="form-inline">
      <div class="input-group">
        <input type="text" id="searchQueryInput" placeholder="How can we help you?">
        <div class="input-group-btn">
          <button class="btn btn-default" type="submit">
          <span class="glyphicon glyphicon-search"></span>
          </button>
        </div>
      </div>
    </form>-->
</div>

<!-- Create a container with four cards -->
<div class="button-details">
    <div class="mx-2">
        <div class="row">
            <!-- Create a card for My Support -->
            <div class="col-sm-3">
                <div class="panel panel-default">
                    <div class="panel-body" role="button" (click)="openMenu('communication-hub')">
                        <div class="button-icon">
                            <i class="fa-regular fa-life-ring"></i>
                        </div>
                        <div>
                            <h4>My Support</h4>
                            <p class="feature-text">Tickets, Projects & Changes</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Create a card for Feature Requests -->
            <div class="col-sm-3">
                <div class="panel panel-default">
                    <div class="panel-body" role="button" (click)="openMenu('feature-requests')">
                        <div class="button-icon">
                            <i class="fa-solid fa-bolt"></i>
                        </div>
                        <div>
                            <h4>Feature Requests</h4>
                            <p class="feature-text">Voting & Betas</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Create a card for Knowledge Base -->
            <div class="col-sm-3">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <div class="button-icon">
                            <i class="fa-regular fa-lightbulb"></i>
                        </div>
                        <div>
                            <h4>Knowledge Base</h4>
                            <p class="feature-text">0 Articles / 27 Categories</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Create a card for AvSight University -->
            <div class="col-sm-3">
                <div class="panel panel-default">
                    <div class="panel-body" role="button" onclick="window.open('https://support.avsight.net/avsight-university/', '_blank')">
                        <div class="button-icon">
                            <i class="fa-solid fa-bullhorn"></i>
                        </div>
                        <div>
                            <h4>AvSight University</h4>
                            <p class="feature-text">13 Courses</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Create a container with placeholders for articles -->
<div class="container" class="mt-4">
    <div class="row">
        <!-- Create a column for popular articles -->
        <div class="col-sm-6 text-center">
            <h4>Popular Articles</h4>
            <!-- Add some dummy content -->
        </div>
        <!-- Create a column for most helpful articles -->
        <div class="col-sm-6 text-center">
            <h4>Most Helpful Articles</h4>
            <!-- Add some dummy content -->
        </div>
    </div>
    <hr />
</div>

<!-- Create a container with a question and a button -->
<div class="container">
    <div class="row">
        <div class="col-sm-12 text-center">
            <div style="margin: 28px 0px 30px 0px">
                <p>Don't see what you're looking for?</p>
            </div>
            <button class="btn btn-primary avsight-btn">Contact Support</button>
        </div>
    </div>
</div>