import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  profileData: any = {};
  editProfileData: any = {};
  profileForm:any = FormGroup;
  caseForm:any = FormGroup;
  loadingButtons: any = {
    isUpdating: false
  }
  caseList: any = [];

  ngOnInit(): void {
    commons.loader.show();
    this.initProfileEditForm();
    this.initCasetForm();
    this.getProfileInfo();
    this.bindEvents();
  }

  initProfileEditForm() {
    var self = this;
    this.profileForm = new FormGroup({
      FirstName: new FormControl(self.editProfileData.FirstName, [
          Validators.required
      ]),
      LastName: new FormControl(self.editProfileData.LastName, [
        Validators.required
      ]),
      Title: new FormControl(self.editProfileData.Title, []),
      CompanyName: new FormControl(self.editProfileData.CompanyName, []),
      Email: new FormControl(self.editProfileData.Email, [
        Validators.required
      ]),
      Phone: new FormControl(self.editProfileData.Phone, []),
      MobilePhone: new FormControl(self.editProfileData.MobilePhone, []),
      Street: new FormControl(self.editProfileData.Address ? self.editProfileData.Address.street : '', []),
      City: new FormControl(self.editProfileData.Address ? self.editProfileData.Address.city : '', []),
      State: new FormControl(self.editProfileData.Address ? self.editProfileData.Address.state : '', []),
      PostalCode: new FormControl(self.editProfileData.Address ? self.editProfileData.Address.postalCode : '', []),
      Country: new FormControl(self.editProfileData.Address ? self.editProfileData.Address.country : '', []),
      AboutMe: new FormControl(self.editProfileData.AboutMe, [])
    });
  }

  initCasetForm() {
    var self = this;
    this.caseForm = new FormGroup({
      Subject: new FormControl(self.editProfileData.Subject, [
          Validators.required
      ]),
      Description: new FormControl(self.editProfileData.Description, [
        Validators.required
      ])
    });
  }

  getProfileInfo() {
    var self = this;
    commons.jqueryAjax(environment.BASE_URL + "user/get", {}, function (res: any) {
      commons.loader.hide();
      if (res.status == 1) {
        self.profileData = res.data;
      }
      self.getCaseList();
    });
  }

  bindEvents() {
    var self = this;
    $("#editProfileModal").on('hidden.bs.modal', function() {
        self.onModalClose();
    });
  }

  showEditProfile() {
    this.editProfileData = JSON.parse(JSON.stringify(this.profileData));
    this.initProfileEditForm();
    $("#editProfileModal").modal("show");
  }

  hideEditProfile() {
    $("#editProfileModal").modal("hide");
  }

  updateProfile() {
    this.loadingButtons.updateClicked = true;
    if (!this.profileForm.valid) {
      return;
    }
    var self = this;
    var param = self.profileForm.value;
    param['Address'] = {
      street: param.Street,
      city: param.City,
      state: param.State,
      postalCode: param.PostalCode,
      country: param.Country
    };
    ['Street', 'City', 'State', 'PostalCode', 'Country'].forEach(prop=>{
      delete param[prop];
    });
    self.loadingButtons.isUpdating = true;
    commons.jqueryAjax(environment.BASE_URL + "user/update", { data: JSON.stringify(param) }, function(res: any) {
        self.loadingButtons.isUpdating = false;
        if (res.status != 1) {
            commons.notification.show({
                type: "error",
                msg: res.message,
                timeout: 3000
            });
            return;
        }
        $("#editProfileModal").modal("hide");
        self.profileData = {...self.profileData, ...param};
    });
  }

  showCreateCase() {
    this.initCasetForm();
    $("#createCaseModal").modal("show");
  }

  hideCreateCase() {
    $("#createCaseModal").modal("hide");
  }

  createCase() {
    this.loadingButtons.createCaseClicked = true;
    if (!this.caseForm.valid) {
      return;
    }
    var self = this;
    var param = self.caseForm.value;
    self.loadingButtons.isCreatingCase = true;
    commons.jqueryAjax(environment.BASE_URL + "case/create", { data: JSON.stringify(param) }, function(res: any) {
        self.loadingButtons.isCreatingCase = false;
        if (res.status != 1) {
            commons.notification.show({
                type: "error",
                msg: res.message,
                timeout: 3000
            });
            return;
        }
        $("#createCaseModal").modal("hide");
        commons.loader.show();
        self.getCaseList();
    });
  }

  getCaseList() {
    var self = this;
    commons.jqueryAjax(environment.BASE_URL + "case/getlist", {}, function (res: any) {
      commons.loader.hide();
      if (res.status == 1) {
        self.caseList = res.data.ticketList;
      }
    });
  }

  onModalClose() {
    this.editProfileData = {};
    this.profileForm.reset();
  }
}
