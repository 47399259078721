import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-copilot',
  templateUrl: './copilot.component.html',
  styleUrl: './copilot.component.css',
  encapsulation: ViewEncapsulation.None
})
export class CopilotComponent implements OnInit {

  chatHistory: any = [];
  chatStats: any = {};
  chatRecentHistory: any = [];
  questionText: any = '';
  maxQuestion: any = 10;
  questionCount: any = 1;
  hasEmail: any = false;
  feedbackId: any = '';

  ngOnInit(): void {
    var url = window.location.href;
    var urlObj = new URL(url);
    this.hasEmail = url.indexOf('public/copilot') == -1 || (url.indexOf('public/copilot') != -1 && this.getEmailFromUrl());
    $("#loader .spinner.message").show();
    var onWindowResize = function() {
      var $chatContainer = $(".chat-container").css({"position": "relative", "height": ""});
      let totalHeight = 0;
      $chatContainer.siblings().each(function(this: any) {
        totalHeight += $(this).outerHeight();
      });
      var margHeight = $chatContainer.outerHeight(true) - $chatContainer.outerHeight() + 55;
      totalHeight = $(window).height() - totalHeight - $(".chat-input-container").height() + margHeight;
      $chatContainer.css("height", (totalHeight + 'px'));
      $(".history-container").css("height", (totalHeight - $(".chat-input-container").height() - 20) + "px");
    };
    setTimeout(function() {
      onWindowResize();
    }, 100);
    $(window).on("resize", onWindowResize);

    $("#copilotFeedback").on('hidden.bs.modal', function() {
      $("#copilotFeedback .feedback-message").val("");
    });

    if (this.hasEmail) {
      this.getChatStats();
    }
  }

  getEmailFromUrl() {
    var urlObj = new URL(window.location.href);
    return urlObj.searchParams.get("email");
  }

  hadleSearch(event: any) {
    if (event.shiftKey) {
      return;
    }
    if (this.questionText.trim().length == 0) {
      event.preventDefault();
      this.questionText = "";
      commons.notification.show({
        type: "error",
        msg: "Please enter search question to continue.",
        timeout: 3000
      });
      return;
    }
    this.questionText = this.questionText.trim();
    let self = this;
    commons.loader.show();
    let time = self.getCurrentDateTime();
    let param = {q: self.questionText, newSession: self.questionCount == 1, email: this.getEmailFromUrl()};
    self.questionText = "";
    commons.jqueryAjax(environment.BASE_URL + "copilot/search", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.chatHistory.push({
        question: {text: param.q, time: time},
        answer: {text: self.parseResponseForLink(res.data), time: self.getCurrentDateTime()}
      });
      setTimeout(function() {
        $(".history-container").animate({ scrollTop: $(".history-container").prop("scrollHeight")}, 700);
      }, 100);
      self.questionCount++;
      commons.trackActivity(environment.BASE_URL + "activity", param.q);
      self.getChatStats();
    });
  }

  getChatStats() {
    let self = this;
    let param = {email: this.getEmailFromUrl()};
    commons.jqueryAjax(environment.BASE_URL + "copilot/stats", {data: JSON.stringify(param)}, function (res: any) {
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      self.chatStats = res.data;
    });
  }

  hadleHistoryClick() {
    let self = this;
    let param = {email: this.getEmailFromUrl()};
    commons.loader.show();
    commons.jqueryAjax(environment.BASE_URL + "copilot/history", {data: JSON.stringify(param)}, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      if (res.data) {
        res.data.reverse();
        var prevSessionId: any = null;
        res.data.forEach((item: any, indx: any)=>{
          item.isNewSession = indx == 0 || (prevSessionId && prevSessionId != item.session_id) ? true : false;
          item.answer = self.parseResponseForLink(item.answer);
          prevSessionId = item.session_id;
        });
      }
      self.chatRecentHistory = res.data;
      $("#copilotHistory").modal("show");
    });
  }
  hideHistoryModal() {
    $("#copilotHistory").modal("hide");
    this.chatRecentHistory = [];
  }

  hadleNewSearch() {
    this.questionCount = 1;
    this.chatHistory = [];
  }

  hadleFeedbackClick() {
    $("#copilotFeedback").modal("show");
  }

  hideFeedbackModal() {
    $("#copilotFeedback").modal("hide");
    $("#copilotFeedback .feedback-message").val("");
  }

  submitFeedback(score: any) {
    let message = $("#copilotFeedback .feedback-message").val();
    if (!message || message.trim().length == 0) {
      commons.notification.show({
        type: "error",
        msg: "Please enter feedback message to continue.",
        timeout: 3000
      });
      return;
    }
    let chatStr = '';
    this.chatHistory.forEach((chat: any) => {
      chatStr += `User (${chat.question.time}): ${chat.question.text}\n\n`;
      chatStr += `CoPilot (${chat.answer.time}): ${chat.answer.text}\n\n\n`;
    });
    let self = this;
    commons.loader.show();
    let param: any = {
      //chat: btoa(chatStr.trim()),
      message: message,
      email: this.getEmailFromUrl(),
      feedbackId: this.feedbackId,
      score: score
    };
    commons.jqueryAjax(environment.BASE_URL + "copilot/feedback", { data: JSON.stringify(param) }, function (res: any) {
      commons.loader.hide();
      if (res.status != 1) {
        commons.notification.show({
          type: "error",
          msg: res.message,
          timeout: 3000
        });
        return;
      }
      commons.notification.show({
        type: "success",
        msg: "Feedback submitted successfully.",
        timeout: 3000
      });
      self.hideFeedbackModal();
    });
  }

  getCurrentDateTime() {
    var d = new Date();
    return d.toLocaleString([], { hour12: true});
  }
  formatDate(date: any) {
    var d = new Date(date);
    return d.toLocaleString([], { hour12: true});
  }
  parseResponseForLink(str: any) {
    let self= this;

    //check for hallucincated results
    if (str.indexOf('__HALLUCINATED__') != -1) {
      return 'Not enough information was available to answer your question completely. Our KB is being updated frequently and our technical writers have been notified about this reply. Check back soon for a better answer to your question.'
    }

    //get feedback id
    let regex = /\[FeedbackID: (.*?)\]/g;
    let matches = Array.from(str.matchAll(regex));
    let result = str;

    matches.forEach((match: any) => {
      self.feedbackId = match[1];
      result = result.replace(match[0], '');
    });
    result = result.trim();

    regex = /\[(.*?)\]\((.*?)\)/g;
    matches = Array.from(str.matchAll(regex));

    matches.forEach((match: any) => {
      const linkText = match[1];
      const url = match[2];
      const link = `<a href="${url}" target="_blank">${linkText}</a>`;
      result = result.replace(match[0], link);
    });

    return result;
  }
}
