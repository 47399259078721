<app-header></app-header>
<div class="container my-4">
    <div class="row">
        <div class="col-md-8">
            <h5>Task Information</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Project Task Name</div>
                    <div class="border-bottom">{{taskData.Name}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Status</div>
                    <div class="border-bottom">{{taskData.AcctSeed__Status__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Project</div>
                    <div class="border-bottom"><a class="text-decoration-none" href="/project-details/{{taskData.AcctSeed__Project__c}}">{{taskData['AcctSeed__Project__r.Name']}}</a>&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">End Date</div>
                    <div class="border-bottom">{{taskData.AcctSeed__End_Date__c ? formatDate(taskData.AcctSeed__End_Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Start Date</div>
                    <div class="border-bottom">{{taskData.AcctSeed__Start_Date__c ? formatDate(taskData.AcctSeed__Start_Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Responsibility</div>
                    <div class="border-bottom">{{taskData['AcctSeed__Responsibility__r.Name']}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Assigned To</div>
                    <div class="border-bottom">{{taskData['Assigned_To_User__r.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">%Complete</div>
                    <div class="border-bottom">{{taskData.Percent_Complete__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Duration</div>
                    <div class="border-bottom">{{taskData.AcctSeed__Duration__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Budgeted Amount</div>
                    <div class="border-bottom">{{taskData.AcctSeed__Budgeted_Amount__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Budgeted Hours</div>
                    <div class="border-bottom">{{taskData.AcctSeed__Budgeted_Hours__c}}&nbsp;</div>
                </div>
            </div>

            <h5 class="mt-4">Instructions</h5>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Task Summary</div>
                    <div class="border-bottom">{{taskData.Task_Summary__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">Internal Instructions</div>
                    <div class="border-bottom">{{taskData.Internal_Instructions__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-text">External Instructions</div>
                    <div class="border-bottom">{{taskData.External_Instructions__c}}&nbsp;</div>
                </div>
            </div>

            <h5 class="mt-4">Additional Task Details</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Project Role Assignment</div>
                    <div class="border-bottom">{{taskData['Project_Role_Assignment__r.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Project Role</div>
                    <div class="border-bottom">{{taskData['Project_Role__r.Name']}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Functional Area</div>
                    <div class="border-bottom">{{taskData.Functional_Area__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Task Type</div>
                    <div class="border-bottom">{{taskData.Task_Type__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Milestone?</div>
                    <div class="border-bottom"><input type="checkbox" disabled (checked)="taskData.Milestone__c" /></div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Parent Task</div>
                    <div class="border-bottom"><a *ngIf="taskData.Parent_Task__c" href="/project-details/{{taskData.Parent_Task__c}}">{{taskData['Parent_Task__r.Name']}}</a>&nbsp;</div>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>