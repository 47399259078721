import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
declare var $: any;
declare var moment: any;
declare var commons: any;

@Component({
  selector: 'app-updaterequests',
  templateUrl: './updaterequests.component.html',
  styleUrl: './updaterequests.component.css'
})
export class UpdaterequestsComponent implements OnInit {
    pagination: any = {
      pageSize: 2,
      totalPage: 1,
      pageNo: 1
    };
    requestList: any = [];
    versionList: any = [];
    requestForm:any = FormGroup;
    loadingButtons: any = {};

    ngOnInit(): void {
      var self = this;
      commons.loader.show();
      self.getUpdateRequests();
      self.initRequestForm();

      $("#createRequestModal").on('hidden.bs.modal', function() {
        self.requestForm.reset();
        self.requestForm.updateValueAndValidity();
      });
    }

    initRequestForm() {
      this.requestForm = new FormGroup({
        SBRequestedDate: new FormControl('', []),
        TargetVersion: new FormControl('', []),
        ProdRequestedDate: new FormControl('', []),
        Comments: new FormControl('', [])
      });
    }

    getUpdateRequests() {
      var self = this;
      var param = {
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.pageNo
      };
      commons.jqueryAjax(environment.BASE_URL + "updaterequest/getlist", { data: JSON.stringify(param)}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.requestList = res.data.updateRequestList;
          if (res.data.totalPage) {
            self.pagination.totalPage = res.data.totalPage;
          }
          if (res.data.versionList) {
            self.versionList = res.data.versionList;
          }
        }
      });
    }

    changeRequestPage(pageNo: any) {
      commons.loader.show();
      this.pagination.pageNo = pageNo;
      this.getUpdateRequests();
    }

    showCreateRequest() {
      this.initRequestForm();
      $("#createRequestModal").modal("show");
    }

    hideCreateRequest() {
      $("#createRequestModal").modal("hide");
    }

    createRequest() {
      this.loadingButtons.createRequestClicked = true;
      if (!this.requestForm.valid) {
        return;
      }
      var self = this;
      var param = self.requestForm.value;
      param.SBRequestedDate = param.SBRequestedDate || null;
      param.ProdRequestedDate = param.ProdRequestedDate || null;
      self.loadingButtons.isCreatingRequest = true;
      commons.jqueryAjax(environment.BASE_URL + "updaterequest/create", { data: JSON.stringify(param) }, function(res: any) {
          self.loadingButtons.isCreatingRequest = false;
          if (res.status != 1) {
              commons.notification.show({
                  type: "error",
                  msg: res.message,
                  timeout: 3000
              });
              return;
          }
          $("#createRequestModal").modal("hide");
          commons.loader.show();
          self.pagination.pageNo = 1;
          self.getUpdateRequests();
      });
    }

    formatDate(date: string, format: string) {
      return moment.utc(date).format(format);
  }
}


