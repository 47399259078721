<app-header></app-header>
<div class="container my-4">
    <div class="row">
        <div class="col-md-8">
            <h5>Project Information</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">AS Project Name</div>
                    <div class="border-bottom">{{projectData.Name}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Status</div>
                    <div class="border-bottom">{{projectData.AcctSeed__Status__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Account</div>
                    <div class="border-bottom">{{projectData['AcctSeed__Account__r.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Customer Satisfaction</div>
                    <div class="border-bottom">{{projectData.Customer_Satisfaction__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Template Project</div>
                    <div class="border-bottom"><input type="checkbox" disabled (checked)="projectData.AcctSeed__Template_Project__c" /></div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Conversion Cutoff Date</div>
                    <div class="border-bottom">{{projectData.Conversion_Cutoff_Date__c ? formatDate(projectData.Conversion_Cutoff_Date__c, 'DD MMM, YYYY') : ''}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Imaging Conversion</div>
                    <div class="border-bottom"><input type="checkbox" disabled (checked)="projectData.Imaging_Conversion__c" /></div>
                </div>
            </div>

            <h5 class="mt-4">Project Scope</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Accounting</div>
                    <div class="border-bottom">{{projectData.Accounting__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Customizations</div>
                    <div class="border-bottom">{{projectData.Customizations__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Conversion From</div>
                    <div class="border-bottom">{{projectData.Conversion_From__c}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Additional Project Details</div>
                    <div class="border-bottom">{{projectData.Additional_Project_Details__c}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Google Project Folder</div>
                    <div class="border-bottom"><a class="text-decoration-none" href="{{(projectData.Google_Project_Folder__c && projectData.Google_Project_Folder__c.indexOf('http') == 0 ? '' : 'https://') + projectData.Google_Project_Folder__c}}" target="_blank">{{projectData.Google_Project_Folder__c}}</a>&nbsp;</div>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>