import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router"
import { environment } from '../../../environments/environment';
declare var commons: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
    loginMessage: any = '';
    allowResend: any = false;
    allowReGenerate: any = false;
    showPreferences: any = false;
    preferenceData: any;

    loginForm = new FormGroup({
        username: new FormControl('', [
            Validators.required
        ]),
        password: new FormControl('', [
            Validators.required
        ])
    });

    loadingButtons: any = {
        login: false,
        clicked: false,
        resend: false
    }

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.verifyLogin();
    }

    verifyLogin() {
        commons.loader.show();
        var self = this;
        var param:any = {};
        let currentUrl = window.location.href;
        var url = new URL(currentUrl);
        if (url.searchParams.get("callback")) {
            var callback = url.searchParams.get("callback") || '';
            url = new URL(decodeURIComponent(callback));
        }
        let originUrls: any = window.location.ancestorOrigins;
        originUrls = originUrls.length > 0 ? originUrls[originUrls.length - 1] : '';
        param["email"] = url.searchParams.get("email");
        param["token"] = url.searchParams.get("token");
        param["username"] = url.searchParams.get("username");
        param['url'] = originUrls ? originUrls : ((window.location != window.parent.location) ? document.referrer : document.location.href);

        if (!param.email) {
            commons.loader.hide();
            this.loginMessage = "This site can only be accessed from within the AvSight product."
            return;
        }

        commons.jqueryAjax(environment.BASE_URL + "verify", { data: JSON.stringify(param) }, function(res:any) {
            commons.loader.hide();
            if (res.status != 1) {
                self.loadingButtons.login = false;
                commons.notification.show({
                    type: "error",
                    msg: res.message,
                    timeout: 3000
                });
                return;
            }
            self.allowResend = res.data && res.data.resend;
            self.allowReGenerate = res.data && res.data.generate;
            if (res.message) {
                self.loginMessage = res.message;
                return;
            }
            if (res.data && res.data.portalEng) {
                if (!res.data.portalEng.seniority) {
                    self.preferenceData = res.data;
                    self.preferenceData.selNotification = res.data.notificationPreferences;
                    self.showPreferences = true;
                    return;
                }
            }
            //commons.validateSession();
        });
    }

    onSignIn() {
        this.loadingButtons.clicked = true;
        if (!this.loginForm.valid) {
            return;
        }
        var self = this;
        var param:any = {};
        param["userName"] = this.loginForm.controls['username'].value;
        param["password"] = this.loginForm.controls['password'].value;

        self.loadingButtons.login = true;
        commons.jqueryAjax(environment.BASE_URL + "login", { data: JSON.stringify(param) }, function(res:any) {
            if (res.status != 1) {
                self.loadingButtons.login = false;
                commons.notification.show({
                    type: "error",
                    msg: res.message,
                    timeout: 3000
                });
                return;
            }
            //commons.validateSession();
        });
    }

    onSendCodeClick(actionType: any) {
        this.loadingButtons.resend = true;
        var self = this;

        var param:any = {};
        let currentUrl = window.location.href;
        var url = new URL(currentUrl);
        if (url.searchParams.get("callback")) {
            var callback = url.searchParams.get("callback") || '';
            url = new URL(decodeURIComponent(callback));
        }
        let originUrls: any = window.location.ancestorOrigins;
        originUrls = originUrls.length > 0 ? originUrls[originUrls.length - 1] : '';
        param["email"] = url.searchParams.get("email");
        param['isGenerate'] = actionType == 'generate';

        self.loadingButtons.login = true;
        commons.jqueryAjax(environment.BASE_URL + "resendtoken", { data: JSON.stringify(param) }, function(res:any) {
            self.loadingButtons.resend = false;
            if (res.status != 1) {
                commons.notification.show({
                    type: "error",
                    msg: res.message,
                    timeout: 3000
                });
                return;
            }
            commons.notification.show({
                type: "success",
                msg: res.message,
                timeout: 3000
            });
            self.allowResend = false;
            self.allowReGenerate = false;
            self.verifyLogin();
        });
    }
}
