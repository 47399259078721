<app-header></app-header>
<app-preferences [preferenceData]="preferenceData"></app-preferences>
<div class="m-4">
    <div class="row">
        <div class="col-md-8">
            <h5>Account Details</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Account Name</div>
                    <div class="border-bottom">{{accountData.contact['Account.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Phone</div>
                    <div class="border-bottom">{{accountData.contact['Account.Phone']}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Parent Account</div>
                    <div class="border-bottom">{{accountData.contact['Account.Parent.Name']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Fax</div>
                    <div class="border-bottom">{{accountData.contact['Account.Fax']}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Website</div>
                    <div class="border-bottom"><a class="text-decoration-none" href="{{(accountData.contact['Account.Website'] && accountData.contact['Account.Website'].indexOf('http') == 0 ? '' : 'https://') + accountData.contact['Account.Website']}}" target="_blank">{{accountData.contact['Account.Website']}}</a>&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Time Zone</div>
                    <div class="border-bottom">{{accountData.contact['Account.Time_Zone__c']}}&nbsp;</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Type</div>
                    <div class="border-bottom">{{accountData.contact['Account.Type']}}&nbsp;</div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Industry</div>
                    <div class="border-bottom">{{accountData.contact['Account.Industry']}}&nbsp;</div>
                </div>
            </div>
            <h5 class="mt-4">Address Information</h5>
            <div class="row mt-4">
                <div class="col-md-6">
                    <div class="form-text">Billing Address</div>
                    <div class="border-bottom">
                        <p class="mb-0">{{accountData.contact['Account.BillingStreet']}}</p>
                        <p class="mb-0">{{accountData.contact['Account.BillingCity']}}, {{accountData.contact['Account.BillingState']}} {{accountData.contact['Account.BillingPostalCode']}}</p>
                        <p class="mb-0">{{accountData.contact['Account.BillingCountry']}}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-text">Shipping Address</div>
                    <div class="border-bottom">
                        <p class="mb-0">{{accountData.contact['Account.ShippingStreet']}}</p>
                        <p class="mb-0">{{accountData.contact['Account.ShippingCity']}}, {{accountData.contact['Account.ShippingState']}} {{accountData.contact['Account.ShippingPostalCode']}}</p>
                        <p class="mb-0">{{accountData.contact['Account.ShippingCountry']}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>