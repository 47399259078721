<app-header></app-header>
<div class="jumbotron text-center">
    <h1 class="mb-5"><b>Copilot</b></h1>
</div>
<div *ngIf="!hasEmail" class="container mt-4 mb-0">
    <p class="text-center">Access denied</p>
</div>
<div *ngIf="hasEmail" class="container mt-4 mb-0 chat-container">
    <div class="row">
        <div class="col-12">
            <div class="card history-container overflow-auto">
                <div class="card-body">
                    <div class="chat-box" *ngIf="chatHistory && chatHistory.length > 0">
                        <ul class="chat">
                            <ng-container *ngFor="let chat of chatHistory; let i = index">
                                <li class="question">
                                    <p>{{chat.question.text}}</p>
                                    <span class="time">You asked on {{chat.question.time}}</span>
                                </li>
                                <li class="answer">
                                    <p [innerHTML]="chat.answer.text"></p>
                                    <span class="time">CoPilot replied on {{chat.answer.time}}</span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="h-100 d-flex justify-content-center align-items-center" *ngIf="!chatHistory || chatHistory.length == 0">
                        <h6 class="text-secondary">
                            Ask your first question...
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 chat-input-container position-absolute">
            <div class="input-group">
                <textarea type="text" [attr.class]="questionCount > maxQuestion ? 'form-control input-box invisible' : 'form-control input-box'" [(ngModel)]="questionText" (keyup.enter)="hadleSearch($event)" placeholder="Type your question..."></textarea>
                <div class="input-group-append btn-container">
                    <button [attr.class]="questionCount > maxQuestion ? 'd-none' : 'btn btn-primary'" (click)="hadleSearch($event)" type="button">Submit {{questionCount}} / {{maxQuestion}}</button>
                </div>
                <div [attr.class]="questionCount == 1 ? 'd-none' : 'input-group-append btn-container'">
                    <button class="btn btn-primary" (click)="hadleNewSearch()" type="button">New Chat</button>
                </div>
                <div [attr.class]="questionCount == 1 ? 'd-none' : 'input-group-append btn-container'">
                    <button class="btn btn-primary" (click)="hadleFeedbackClick()" type="button">Beta Feedback</button>
                </div>
            </div>
            <div class="stat-info">
                YOU USED COPILOT {{chatStats.total_sessions}} TIME(S) AND ASKED {{chatStats.total_requests}} QUESTION(S) IN THE LAST 30 DAYS
                <a href="javascript:void(0)" class="ml-2" (click)="hadleHistoryClick()">RECENT CHAT HISTORY</a>
            </div>
        </div>
    </div>

    <!-- send copilot feedback -->
    <div class="modal fade" id="copilotFeedback" role="dialog" aria-labelledby="copilotFeedbackLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="copilotFeedbackLabel">Send Beta Feedback</h5>
                    <button class="close" type="button" (click)="hideFeedbackModal()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-text">Message<span class="text-danger">*</span></div>
                            <textarea type="text" class="form-control feedback-message" rows="8" placeholder="Type your message..."></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" (click)="submitFeedback(1)"><i class="fa-solid fa-thumbs-up"></i> Like It</button>
                    <button class="btn btn-primary" type="button" (click)="submitFeedback(0)"><i class="fa-solid fa-thumbs-down"></i> Needs Work</button>
                </div>
            </div>
        </div>
    </div>

    <!-- show recent chat history -->
    <div class="modal fade" id="copilotHistory" role="dialog" aria-labelledby="copilotHistoryLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="copilotHistoryLabel">Recent Chat History</h5>
                    <button class="close" type="button" (click)="hideHistoryModal()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 max-height">
                            <div class="chat-box" *ngIf="chatRecentHistory && chatRecentHistory.length > 0">
                                <ul class="chat">
                                    <ng-container *ngFor="let chat of chatRecentHistory; let i = index">
                                        <li [class]="'question' + (i != 0 && chat.isNewSession ? ' border-top' : '' )">
                                            <div *ngIf="chat.isNewSession" class="text-center">{{formatDate(chat.created_on)}}</div>
                                            <p>{{chat.user_query}}</p>
                                            <span class="time">Your query</span>
                                        </li>
                                        <li class="answer">
                                            <p [innerHTML]="chat.answer"></p>
                                            <span class="time">CoPilot response</span>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" (click)="hideHistoryModal()" data-dismiss="modal">Close History</button>
                </div>
            </div>
        </div>
    </div>
</div>