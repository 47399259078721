import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
declare var $: any;
declare var commons: any;

@Component({
  selector: 'app-featurerequests',
  templateUrl: './featurerequests.component.html',
  styleUrl: './featurerequests.component.css'
})
export class FeaturerequestsComponent implements OnInit {
    filterParam: any = {
      selectedCategory: '',
      sortType: 'Popular',
      seletedStatus: '',
      showMyReq: false
    };
    pagination: any = {
      pageSize: 100,
      totalPage: 1,
      pageNo: 1
    };
    featureReqData: any = {
      featurePointsCategoryList: [],
      featurePointsStatusList: []
    };
    featureRequestList: any = [];
    voteObj: any = {};
    newRequestForm:any = FormGroup;
    loadingButtons: any = {
      isSaving: false
    }

    ngOnInit(): void {
      commons.loader.show();
      this.getRequiredData();
      this.getFeatureRequests();

      this.newRequestForm = new FormGroup({
        Name: new FormControl('', [
            Validators.required
        ]),
        Description: new FormControl('', []),
        Category: new FormControl('', [])
      });
    }

    getRequiredData() {
      var self = this;
      commons.jqueryAjax(environment.BASE_URL + "featurerequest/requiredlist", {}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.featureReqData = res.data;
        }
      });
    }

    getFeatureRequests() {
      var self = this;
      this.filterParam.searchText = this.filterParam.searchText || '';
      var param = JSON.parse(JSON.stringify(this.filterParam));
      param.pageSize = this.pagination.pageSize;
      param.pageNo = this.pagination.pageNo;
      commons.jqueryAjax(environment.BASE_URL + "featurerequest/getlist", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.featureRequestList = res.data.featureList;
          if (res.data.totalPage) {
            self.pagination.totalPage = res.data.totalPage;
          }
        }
      });
    }

    changePage(pageNo: any) {
      commons.loader.show();
      this.pagination.pageNo = pageNo;
      this.getFeatureRequests();
    }

    checkForEnter(event: any) {
      if (event.which === 13) {
        this.onFilterChange();
      }
    }

    onFilterChange() {
      commons.loader.show();
      this.getFeatureRequests();
    }

    onFeatureItemClick(event: any) {
      var indx = +event.currentTarget.dataset.index;
      var type = event.target.dataset.type;
      var type = type || event.currentTarget.dataset.type;
      var obj = JSON.parse(JSON.stringify(this.featureRequestList))[indx];
      obj.isView = type == 'view';
      this.voteObj = obj;
      if (obj.isView) {
        $("#featureReqDataModal").modal("show");
      } else {
        if (this.featureReqData.remainingFeaturePoints < 1) {
          commons.notification.show({
            type: "error",
            msg: 'You have no points available.',
            timeout: 3000
          });
          return;
        }
        commons.loader.show();
        this.updateVote(true);
      }
    }

    updateVote(isValidate: any) {
      var self = this;
      var param = {
        featureRequestId: self.voteObj.Id,
        isValidate: isValidate
      };
      commons.jqueryAjax(environment.BASE_URL + "featurerequest/vote", { data: JSON.stringify(param) }, function (res: any) {
        if (res.status != 1) {
          commons.loader.hide();
          commons.notification.show({
            type: "error",
            msg: res.message,
            timeout: 3000
          });
          return;
        }
        if (!isValidate) {
          self.getRequiredData();
          self.getFeatureRequests();
          return;
        }
        if (res.data && res.data.isExistingVote) {
          let res = confirm('You have already upvoted this feature request, do you want to remove your vote?');
          if (res == true) {
            self.updateVote(false);
          } else {
            commons.loader.hide();
          }
        } else {
          self.updateVote(false);
        }
      });
    }

    hideVoteDialog() {
      $("#featureReqDataModal").modal("hide");
    }

    createFeatureRequest() {
      this.loadingButtons.saveClicked = true;
      if (!this.newRequestForm.valid) {
        return;
      }
      var self = this;
      var param = self.newRequestForm.value;
      param.Category = param.Category.join(";");
      self.loadingButtons.isSaving = true;
      commons.jqueryAjax(environment.BASE_URL + "featurerequest/create", { data: JSON.stringify(param) }, function(res: any) {
          self.loadingButtons.isSaving = false;
          if (res.status != 1) {
              commons.notification.show({
                  type: "error",
                  msg: res.message,
                  timeout: 3000
              });
              return;
          }
          commons.loader.show();
          self.newRequestForm.reset();
          self.newRequestForm.controls.Name.setErrors(null);
          self.newRequestForm.updateValueAndValidity();
          self.getFeatureRequests();
      });
    }
}
