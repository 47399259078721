
<router-outlet *ngIf="isVerified"></router-outlet>
<div class="main-container" *ngIf="!isVerified">
    <div *ngIf="loginMessage && loginMessage.length > 0" class="message-box">
        <div class="d-flex justify-content-center align-items-center">
            {{loginMessage}}
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showEmailInput">
        <div class="col-md-12">
            <h5>Welcome to AvSight IMS Portal</h5>
            <p>To use IMS Portal enter your email address below.</p>
            <div class="row mt-4">
                <div class="col-12 mb-4">
                    <h6>Your Email</h6>
                    <input class="form-control" type="email" maxlength="255" [(ngModel)]="newUserData.email">
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block"(click)="verifyEmail()">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showAccessCode">
        <div class="col-md-12">
            <p>A 4 digit access code was sent to the email address you entered. Enter that code below to continue.</p>
            <div class="row mt-4">
                <div class="col-12 mb-4">
                    <h6>Access Code</h6>
                    <input class="form-control" type="text" maxlength="4" [(ngModel)]="newUserData.accessCode">
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary me-2 btn-block"(click)="backToEmailInput()">Back</button>
                    <button type="button" class="btn btn-primary btn-block"(click)="verifyCode()">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-2 my-3" *ngIf="showPreferences">
        <div class="col-md-12">
            <h5>Welcome to AvSight Copilot</h5>
            <p>In order to serve you better please fill out the following short survey. This information will help us to create an account for you and provide relevant information tailored to your role.</p>
            <div class="row mt-4">
                <div class="col-12 mb-4">
                    <h6>Company</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.companyName">
                </div>
                <div class="col-12 mb-4">
                    <h6>Your Name</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.name">
                </div>
                <div class="col-12 mb-4">
                    <h6>Contact #</h6>
                    <input class="form-control" type="text" maxlength="255" [(ngModel)]="preferenceData.contact.phone">
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Role</h6>
                    <div *ngFor="let role of preferenceData.roles; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="role" [checked]="preferenceData.selRoles && preferenceData.selRoles.indexOf(role) != -1" [id]="'role' + i">
                        <label class="form-check-label ps-1" [for]="'role' + i">
                            {{role}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Seniority</h6>
                    <div *ngFor="let seniority of preferenceData.seniorities; let i = index">
                        <input class="form-check-input" type="radio" name="seniority" [value]="seniority" [checked]="preferenceData.selSenioirty && preferenceData.selSenioirty == seniority" [id]="'seniority' + i">
                        <label class="form-check-label ps-1" [for]="'seniority' + i">
                            {{seniority}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Notification Preferences</h6>
                    <div *ngFor="let pref of preferenceData.notificationPreferences; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="pref" [checked]="preferenceData.selNotification && preferenceData.selNotification.indexOf(pref) != -1" [id]="'pref' + i">
                        <label class="form-check-label ps-1" [for]="'pref' + i">
                            {{pref}}
                        </label>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h6>Business Segments</h6>
                    <div *ngFor="let seg of preferenceData.businessSegments; let i = index">
                        <input class="form-check-input" type="checkbox" [value]="seg" [checked]="preferenceData.selBusinessSegments && preferenceData.selBusinessSegments.indexOf(seg) != -1" [id]="'seg' + i">
                        <label class="form-check-label ps-1" [for]="'seg' + i">
                            {{seg}}
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block"(click)="updatePreferences()">Save and Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>