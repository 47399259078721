import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare var commons: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent implements OnInit {

  accountData: any = {};
  preferenceData: any = {};

  ngOnInit(): void {
    commons.loader.show();
    this.getAccountInfo();
  }

  getAccountInfo() {
    var self = this;
    commons.jqueryAjax(environment.BASE_URL + "user/account", {}, function (res: any) {
      commons.loader.hide();
      if (res.status == 1) {
        self.accountData = res.data;
        self.preferenceData = res.data;
        self.preferenceData.selRoles = res.data.portalEng.Role__c ? res.data.portalEng.Role__c.split(";") : [];
        self.preferenceData.selSenioirty = res.data.portalEng.Seniority__c ? res.data.portalEng.Seniority__c : '';
        self.preferenceData.selNotification = res.data.portalEng.Notification_Preferences__c ? res.data.portalEng.Notification_Preferences__c.split(";") : [];
        self.preferenceData.selBusinessSegments = res.data.portalEng.Business_Segments__c ? res.data.portalEng.Business_Segments__c.split(";") : [];
        self.preferenceData.tpnDirectory = res.data.portalEng.TPN_Directory__c;
        self.preferenceData.isFromAccount = true;
      }
    });
  }
}
