import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../environments/environment';
import '../../../assets/js/kanban.js';
import '../../../assets/js/dhtmlxgantt.js';
declare var $: any;
declare var moment: any;
declare var commons: any;
declare var $$: any;
declare var webix: any;
declare var gantt: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-communicationhub',
  templateUrl: './communicationhub.component.html',
  styleUrls: [
    './../../../assets/css/kanban.css',
    './../../../assets/css/dhtmlxgantt.css',
    './communicationhub.component.css',
  ]
})
export class CommunicationhubComponent implements OnInit {
    pagination: any = {
      pageSize: 100,
      tickets: {
        totalPage: 1,
        pageNo: 1
      },
      cr: {
        totalPage: 1,
        pageNo: 1
      }
    };
    ticketList: any = [];
    crList: any = [];
    ticketForm:any = FormGroup;
    issueForm:any = FormGroup;
    projectTabData: any = {
      projectList: [],
      selProject: '',
      selProjectData: {},
      taskList: {},
      project: {
        dueFilter: ''
      },
      kanbanData: {},
      crList: [],
      issueData: {},
      overview: {}
    };
    loadingButtons: any = {};
    kanban: any;

    ngOnInit(): void {
      var self = this;
      commons.loader.show();
      self.getTicketList();
      self.initTicketForm();
      self.initIssuetForm();

      $("#createTicketModal").on('hidden.bs.modal', function() {
        self.ticketForm.reset();
        self.ticketForm.controls.Subject.setErrors(null);
        self.ticketForm.controls.Description.setErrors(null);
        self.ticketForm.updateValueAndValidity();
        self.loadingButtons.createTicketClicked = false;
      });

      $("#createIssueModal").on('hidden.bs.modal', function() {
        self.issueForm.reset();
        self.issueForm.controls.Title.setErrors(null);
        self.issueForm.controls.FunctionalArea.setErrors(null);
        self.issueForm.controls.IssueType.setErrors(null);
        self.issueForm.updateValueAndValidity();
        self.loadingButtons.createIssueClicked = false;
      });
    }

    initTicketForm() {
      this.ticketForm = new FormGroup({
        Subject: new FormControl('', [
            Validators.required
        ]),
        Description: new FormControl('', [
          Validators.required
        ])
      });
    }

    initIssuetForm() {
      this.issueForm = new FormGroup({
        Title: new FormControl('', [
            Validators.required
        ]),
        FunctionalArea: new FormControl('', [
          Validators.required
        ]),
        IssueType: new FormControl('', [
          Validators.required
        ]),
        Description: new FormControl('', []),
        WorkStarts: new FormControl('', []),
        WorkEnds: new FormControl('', []),
        Priority: new FormControl('', []),
        ReportedBy: new FormControl('', []),
      });
    }

    onChangeTab(tabName:string) {
      if (tabName == 'cr' && this.crList.length == 0) {
        commons.loader.show();
        this.getChangeRequestList();
      } else if (tabName == 'projects' && this.projectTabData.projectList.length == 0) {
        commons.loader.show();
        this.getProjectList();
      }
    }

    getTicketList() {
      var self = this;
      var param = {
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.tickets.pageNo
      };
      commons.jqueryAjax(environment.BASE_URL + "case/getlist", { data: JSON.stringify(param)}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.ticketList = res.data.ticketList;
          if (res.data.totalPage) {
            self.pagination.tickets.totalPage = res.data.totalPage;
          }
        }
      });
    }

    changeTicketPage(pageNo: any) {
      commons.loader.show();
      this.pagination.tickets.pageNo = pageNo;
      this.getTicketList();
    }

    showCreateTicket() {
      this.initTicketForm();
      $("#createTicketModal").modal("show");
    }

    hideCreateTicket() {
      $("#createTicketModal").modal("hide");
    }

    createTicket() {
      this.loadingButtons.createTicketClicked = true;
      if (!this.ticketForm.valid) {
        return;
      }
      var self = this;
      var param = self.ticketForm.value;
      self.loadingButtons.isCreatingTicket = true;
      commons.jqueryAjax(environment.BASE_URL + "case/create", { data: JSON.stringify(param) }, function(res: any) {
          self.loadingButtons.isCreatingTicket = false;
          if (res.status != 1) {
              commons.notification.show({
                  type: "error",
                  msg: res.message,
                  timeout: 3000
              });
              return;
          }
          $("#createTicketModal").modal("hide");
          commons.loader.show();
          self.pagination.tickets.pageNo = 1;
          self.getTicketList();
      });
    }

    getChangeRequestList() {
      var self = this;
      var param = {
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.cr.pageNo
      };
      commons.jqueryAjax(environment.BASE_URL + "changerequest/getlist", { data: JSON.stringify(param)}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.crList = res.data.changeRequestList;
          if (res.data.totalPage) {
            self.pagination.cr.totalPage = res.data.totalPage;
          }
        }
      });
    }

    changeCRPage(pageNo: any) {
      commons.loader.show();
      this.pagination.cr.pageNo = pageNo;
      this.getChangeRequestList();
    }

    getProjectList() {
      var self = this;
      commons.jqueryAjax(environment.BASE_URL + "project/getlist", {}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.projectTabData.projectList = res.data;
        }
      });
    }

    getProjectTaskList() {
      var self = this;
      var param = {
        projectId: this.projectTabData.selProject
      }
      commons.jqueryAjax(environment.BASE_URL + "project/taskreport", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.projectTabData.taskList = res.data;
        }
      });
    }

    onProjectChange() {
      if (this.projectTabData.selProject) {
        this.projectTabData.selProjectData = this.projectTabData.projectList.find((proj: any) => proj.Id == this.projectTabData.selProject);
      }
      var selTab = $("#nav-subTabContent .active").attr("id");
      switch(selTab) {
        case 'nav-kanban':
          if (this.projectTabData.selProject) {
            this.getKanbanData();
          } else {
            if (this.kanban) {
              this.kanban.destructor();
            }
            this.projectTabData.kanbanData = {};
          }
          break;
        case 'nav-report':
          if (this.projectTabData.selProject) {
            commons.loader.show();
            this.getProjectTaskList();
          } else {
            this.projectTabData.taskList = {};
          }
          break;
        case 'nav-overview':
          if (this.projectTabData.selProject) {
            commons.loader.show();
            this.getProjectTaskGrid();
          } else {
            this.projectTabData.overview = {};
          }
          break;
      }
    }

    getKanbanData() {
      if (!this.projectTabData.selProject) {
        return;
      }
      var self = this;
      var param = {
        projectId: this.projectTabData.selProject,
        userFilter: $("#showMyIssues").is(":checked") ? 'Assigned' : '',
        dueDateFilter: this.projectTabData.project.dueFilter,
      }
      commons.loader.show();
      commons.jqueryAjax(environment.BASE_URL + "project/kanban/requiredlist", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.projectTabData.kanbanData = res.data;
          self.initKanban();
        }
      });
    }

    initKanban() {
      if (this.kanban) {
        this.kanban.destructor();
      }
      let self = this;
      const isKanBanEdit = this.projectTabData.kanbanData.kanbanEdit;
      const isCommunity = true;
      const projectId = this.projectTabData.selProject;
      var users = this.projectTabData.kanbanData.users ? JSON.parse(this.projectTabData.kanbanData.users) : [];
      var userRoleAssignments = this.projectTabData.kanbanData.userRoleAssignments ? JSON.parse(this.projectTabData.kanbanData.userRoleAssignments) : [];
      var tasks = this.projectTabData.kanbanData.tasks ? JSON.parse(this.projectTabData.kanbanData.tasks) : [];
	    var tags_set = this.projectTabData.kanbanData.labels ? JSON.parse(this.projectTabData.kanbanData.labels) : [];
      const cols = this.projectTabData.kanbanData.columns ? JSON.parse(this.projectTabData.kanbanData.columns) : [];
      var statusList = this.projectTabData.kanbanData.statusList ? JSON.parse(this.projectTabData.kanbanData.statusList) : [];
      var functionalAreas = this.projectTabData.kanbanData.functionalAreas ? JSON.parse(this.projectTabData.kanbanData.functionalAreas) : [];
      var statusToUpdate: any = null;
      const frameHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const userIdToNameMap: any = {};
      var mentionedUsers: any = [];
      users = users.filter(function(user: any) {
        return user.is_active;
      });
      if (users && users.length > 0) {
        users.sort(function(a: any, b: any) {
          userIdToNameMap[a.id] = a.value;
          userIdToNameMap[b.id] = b.value;
          return a.value.localeCompare(b.value);
        });
      }
      if (userRoleAssignments && userRoleAssignments.length > 0) {
        userRoleAssignments = JSON.parse(userRoleAssignments);
        userRoleAssignments.sort(function(a: any, b: any) {
          return a.value.localeCompare(b.value);
        });
      } else {
        userRoleAssignments = [];
      }

      function onAfterDrop(dragContext: any, e: any, list: any) {
        var prevId = list.data.order[dragContext.index - 1];
        var nextId = list.data.order[dragContext.index + 1];
        var item = $$('myBoard').getItem(dragContext.start); // Dragged Item

        var position = 0;
        if (prevId) {
          let prevItem = $$('myBoard').getItem(prevId);
          position += prevItem.position;
          if (!nextId) {
            position += 100;
          }
        }
        if (nextId) {
          let nextItem = $$('myBoard').getItem(nextId);
          position += nextItem.position;
          if (!prevId) {
            position -= 100;
          } else {
            position = position / 2;
          }
        }
        var status = null;
        // if we move an item from one list to another
        if (dragContext.from != dragContext.to) {
          status = dragContext.to.config.status;
          // show a message with new status and order
          webix.message("'"+item.text+"' was moved into '"+status+"' column to the "+item.$index +" position");
        } else {
          webix.message("'"+item.text+"' was moved to the "+item.$index +" position");
        }

        if (status || (position != item.position)) {
          status = status ? status : item.status;
          var param = {
            cardId: item.id,
            status: status,
            position: position + '',
            statusOnly: 'true'
          }
          self.updateCardDetails(param);
          item.status = status;
          item.position = position;
        }
      }

      function cardUpdateSave(id: any, data: any, old: any) {
        if (data) {
          commons.loader.show();
          var tags = '';
          if (data.tags) {
              tags = data.tags.join(',')
          }
          if (document.querySelectorAll('.due-date')) {
            var $date = document.querySelectorAll('.due-date')[0];
              if ($date && $date.querySelector('[aria-label="Work Ends"]')) {
                data.due_date = $date.querySelector('[aria-label="Work Ends"]')?.textContent;
              }
          }
          if (document.querySelectorAll('.start-date')) {
            var $date = document.querySelectorAll('.start-date')[0];
              if ($date && $date.querySelector('[aria-label="Work Starts"]')) {
                data.start_date = $date.querySelector('[aria-label="Work Starts"]')?.textContent;
              }
          }
          if (!data.start_date && data.start_date_obj) {
            data.start_date = data.start_date_obj.toLocaleDateString("en-US");
          }
          if (!data.due_date && data.due_date_obj) {
            data.due_date = data.due_date_obj.toLocaleDateString("en-US");
          }
          var status = data.status;
          if (statusToUpdate && statusToUpdate.length > 0) {
            status = statusToUpdate;
              var obj = $$('myBoard').getItem(data.id);
              obj.status = status;
              statusToUpdate = null;
              $$("myBoard").updateItem(id, obj);
          }
          var param = {
            cardId: data.id,
            desc: data.description,
            status: status,
            assigned: data.assigned_id,
            user: data.user_id,
            title: data.text,
            tags: tags,
            dueDate: data.due_date,
            startDate: data.start_date,
            functionalArea: data.functional_area,
            statusOnly: 'false'
          }
          self.updateCardDetails(param);
          webix.message("'"+data.text+"' updated successfully");
          statusToUpdate = null;
          setCardBackground();
        }
      }

			var openCard: any;
      function onCardOpen(itemId: any, ev: any, node: any, list: any) {
        statusToUpdate = null;
        openCard = itemId;
        $(".role-assgn:first").css('display', 'inline-block');
        $(".google-link:first").css('display', 'inline-block');
        $(".instruction:first").css('display', 'inline-block');
        $(".cr-link:first").css('display', 'inline-block');
        $(".new-cr-link:first").css('display', 'inline-block');
        $(".existing-cr-link:first").css('display', 'inline-block');
        var obj = $$('myBoard').getItem(openCard);
        if (!obj.google_document_link || !obj.is_task) {
          $(".google-link:first").css('display', 'none');
        }
        if (!obj.is_task) {
          $(".instruction:first").css('display', 'none');
        }
        if (obj.is_task) {
          $(".existing-cr-link:first").css('display', 'none');
        }
        $(".cr-link:first").css('display', 'none');
        $(".new-cr-link:first").css('display', 'none');
        $(".existing-cr-link:first").css('display', 'none');
        $(".role-assgn:first").css('display', 'none');
        setTimeout(function() {
          hideInActiveUsers();
        }, 1001);

        let elList = $$("myBoard").getEditor()._form.elements;
        for (var key in elList) {
          var el = elList[key];
          el.config.readonly = obj.status == 'Complete' || isCommunity;
          if (key == 'assigned_id') {
            el.config.readonly = false;
          }
          el.refresh();
          if (key == 'status') {
            try {
              el.$view.style.display = isCommunity ? 'none' : 'block';
              el.config.readonly = false;
            } catch (ex) {}
          }
          if (key == 'attachments') {
            try {
              if (obj.status == 'Complete' || isCommunity) {
                el.disable();
                $$(el.config.link).disable();
              } else {
                el.enable();
                $$(el.config.link).enable();
              }
            } catch (ex) {}
          }
          el.refresh();
        }

        setTimeout(function() {
          $(".webix_kanban_editor .webix_el_button").css('visibility', 'hidden');
        }, 100);
      }
      function onCardAction (action: any, id: any) {
      }
      function reset() {
				$$("myBoard").filter("","");
				$$("filterList").unselect();
			}
      function openCommentBox(e: any) {
        var $content = e.closest('.webix_kanban_list_content');
        if (!$content || $content.length == 0) return;
        var $comment = $content.querySelectorAll('[webix_icon_id="comments"]');
        if ($comment && $comment.length > 0) {
          $comment[0].click();
        }
      }
      function hideInActiveUsers() {
        var obj = $$('myBoard').getItem(openCard);
        var $options, isHide: any;
        users.forEach(function(user: any) {
          $options = document.querySelectorAll('[view_id^="$suggest"] [webix_l_id="' + user.id + '"]');
          isHide = user.account_id && user.account_id.length > 0 && user.account_id != obj.account_id;
          $options.forEach(function(el) {
            el.className = !user.is_active || isHide ? 'hide-el' : 'webix_list_item';
          });
        });
      }
      function adjustCardMargin() {
        setTimeout(function() {
          var totalBanner: any;
          document.querySelectorAll('[webix_l_id]').forEach(function(el: any) {
            totalBanner = 0;
            el.querySelectorAll('.red-banner').forEach(function($banner: any) {
              $banner.style.bottom = -(20 + (20 * totalBanner) + totalBanner) + 'px';
                totalBanner++;
            });
            el.style.marginBottom = ((20 * totalBanner) + totalBanner) + 'px';
          });
        }, 251);
        window.dispatchEvent(new Event('resize'));
      }
      function setCardBackground() {
        document.querySelectorAll('.grey-back').forEach(function(el: any) {
          el.closest(".webix_kanban_list_content").style.background = 'lightgrey';
        });
      }

      webix.ready(function() {
        if (!webix.env.touch && webix.env.scrollSize)
            webix.CustomScroll.init();

        webix.type(webix.ui.kanbanlist, {
          name: "images",
          templateBody: function(obj: any) {
            obj.due_date_obj = obj.due_date ? new Date(obj.due_date) : '';
            obj.start_date_obj = obj.start_date ? new Date(obj.start_date) : '';
            var html = "";
            if(obj.image)
              html += "<img class='image' width='100%' src='"+obj.image+"'/>";

            html += "<div class='content-block'>" +obj.text
              + (obj.description ? ("<span class='description'>" + obj.description + "</span>") : "");
            html += obj.start_date ? ("<span class='duedate'>Work Starts: " + obj.start_date + "</span>") : "";
            html += obj.due_date ? ("<span class='duedate " + (obj.status != 'Complete' && obj.is_due ? 'due' : '') + "'>Work Ends: " + obj.due_date + "</span>") : "";
            //html += obj.status == 'Complete' && obj.date_completed ? ("<span class='duedate'>Complete: " + obj.date_completed + "</span>") : "";
            html += "<span class='description small'>Assigned: " + (userIdToNameMap[obj.assigned_id] || '') + "</span>";
            html += "<span class='description small'>Responsible: " + (obj.responsible  || '') + "</span>";
            html += obj.urgency ? "<span class='description small'>Urgency: " + obj.urgency + "</span>" : "";
            if (projectId == 'all') {
              html += "<span class='description'>Project: " + (obj.project_name || '') + "</span>";
            }
            if (obj.cr_detail) {
              html += "<span class='description small'>" + obj.cr_detail + "</span>";
            }
            html += "</div>";
            if (projectId == 'all' && obj.payment_late) {
              html += "<div class='payment-late red-banner'>PAYMENT LATE</div>";
            }
            html += obj.need_reply ? `<div class='need-reply red-banner'>NEEDS REPLY</div>` : "";
            return html;
          },
          templateAvatar: function (obj: any) {
            if (obj.user_id) {
              var user;
              for (var i = 0; i < users.length && !user;i++) {
                if (users[i].id == obj.user_id) {
                  user = users[i];
                }
              }
              if (user && user.image)
                return "<img class='webix_kanban_avatar' src='"+user.image+"' title='"+(user.value||"") + (user.role ? ('&#10;' + user.role) : '') +"'>";
              return "<span title='"+(user && user.value ? user.value : "")+"'></span>";
            }
            return "<span class='webix_icon wxi-user'></span>";
          }
        });

        var storedData = localStorage.getItem('swimLaneData');
        storedData = storedData ? JSON.parse(storedData) : [];
        for (var i = 0; i < cols.length; i++) {
          if (storedData?.indexOf(cols[i].value) != -1) {
            cols[i].collapsed = true;
          }
        }

        mentionedUsers = [];
        var kanbanConfig: any = {
          container: $("#kanbanBoard")[0],
          view: "kanban",
          id : "myBoard",
          height: frameHeight - 10,
          users: users,
          editor: isKanBanEdit,
          on: {
            onListAfterDrop: onAfterDrop,
            onDataUpdate: cardUpdateSave,
            onListItemDblClick: function(itemId: any, ev: any, node: any, list: any) {
                onCardOpen(itemId, ev, node, list);
            },
            onListBeforeDrop: function() {return isKanBanEdit;},
            onBeforeCardAction:onCardAction,
            onListItemClick: function() {
              adjustCardMargin();
            }
          },
          comments: {
            currentUser: self.projectTabData.kanbanData.currentUser,
            keepButtonVisible: true,
            mentions:true,
            on:{
              onBeforeLoad:function() {
                mentionedUsers = [];
              },
              onUserMentioned:function(userId: any, id: any) {
                mentionedUsers.push(userId);
              }
            }
          },
          cols: cols,
          tags: tags_set,
          data: tasks,
          attachments: '?id=' + self.projectTabData.selProject
        };

        if (isKanBanEdit) {
          kanbanConfig["editor"] = {
          elements:[
            { view:"label", name:"project_name", label:"", css: 'text-center cust-link', click:function() {
              if (!openCard) {
                  return;
              }
              var obj = $$('myBoard').getItem(openCard);
              window.open('/project-details/' + obj.project_id, "_blank");
            }},
            { view:"label", name:"text", label:"", css: 'text-center cust-link task-issue', click:function() {
              if (!openCard) {
                  return;
              }
              var obj = $$('myBoard').getItem(openCard);
              window.open('/' + (obj.is_task ? 'task-details/' : 'issue-details/') + obj.id, "_blank");
            }},
              //{ view:"text", name:"text", label:"Task/Issue"},
            { view:"textarea", name:"description", label:"Description", height:90 },
            { view:"combo", name:"functional_area", readonly:true, label:"Functional Area",options:functionalAreas},
            { view:"combo", name:"user_id", readonly:true, label:"Responsibility",options:users},
            { cols:[
                { view:"combo", name:"assigned_id", label:"Assign to", options: (userRoleAssignments), on:{
                onChange: function(newValue: any, oldValue: any, config: any) {
                    setTimeout(function() {
                        hideInActiveUsers();
                    }, 1001);
                  }
                }},
                { view:"multicombo", name:"tags", label:"Tags", options:tags_set }
              ]
            },
            { view:"label", name:"", label:"Project Role Assignments", css: 'cust-link role-assgn', click:function() {
              if (!openCard) {
                  return;
              }
              var obj = $$('myBoard').getItem(openCard);
              //openRolesPopup(obj.project_id);
            }},
            { view:"datepicker", name:"start_date_obj", label:"Work Starts", css: 'start-date'},
            { cols:[
                { view:"datepicker", name:"due_date_obj", label:"Work Ends", css: 'due-date'},
                { view:"text", name:"internal_instructions", label:"Internal Instructions", css: 'instruction internal'},
                { view:"text", name:"external_instructions", label:"External Instructions", css: 'instruction external'}
            ]},
            { view:"combo", name:"status", label:"Update Status", options: statusList, on:{
                onChange: function(newValue: any, oldValue: any, config: any) {
                  if (!openCard) {
                      return;
                  }
                  statusToUpdate = newValue;
                }
              }},
              { cols:[
                { view:"label", name:"", label:"Google Link", css: 'cust-link google-link', click:function() {
                  if (!openCard) {
                      return;
                  }
                  var obj = $$('myBoard').getItem(openCard);
                  window.open(obj.google_document_link, '_blank');
                }},
                { view:"label", name:"", label:"Comments", css: 'cust-link', click:function() {
                  if (!openCard) {
                      return;
                  }
                  var obj = $$('myBoard').getItem(openCard);
                  var $card = document.querySelectorAll('[webix_l_id="' + obj.id + '"]')[0];
                  openCommentBox($card.querySelectorAll('.webix_kanban_body')[0]);
                }}
              ]},
              { view:"label", name:"cr_name", label:"", css: 'cust-link cr-link', click:function() {
                if (!openCard) {
                    return;
                }
                var obj = $$('myBoard').getItem(openCard);
                window.open('/' + obj.cr_id, '_blank');
              }},
              { view:"label", name:"", label:"New Change Request", css: 'cust-link new-cr-link', click:function() {
                if (!openCard) {
                    return;
                }
                var obj = $$('myBoard').getItem(openCard);
                var url = '/lightning/cmp/c__ChangeRequestCreate?c__recId=' + obj.id;
                window.open(url, '_blank');
              }},
              { view:"label", name:"", label:"Existing Change Request", css: 'cust-link existing-cr-link', click:function() {
                if (!openCard) {
                    return;
                }
                var obj = $$('myBoard').getItem(openCard);
                var url = '/lightning/cmp/c__ChangeRequestExisting?c__recId=' + obj.id;
                window.open(url, '_blank');
              }}
            ]
          };
        }

        kanbanConfig["editor"].elements[8].cols.splice(1, 1);//remove internal instruction for community users

        self.kanban = webix.ui(kanbanConfig);

        adjustCardMargin();
        setCardBackground();
        //document.querySelector('.webix_dataview_item').click(); //Select All option by default
        function updateSwimLaneCollapse(id: any, isCollapse: any) {
          var storedData: any = localStorage.getItem('swimLaneData');
          storedData = storedData ? JSON.parse(storedData) : [];
          for (var i = 0; i < cols.length; i++) {
            if (cols[i].id == id) {
              if (isCollapse) {
                storedData.push(cols[i].value);
              } else {
                storedData?.splice(storedData.indexOf(cols[i].value), 1);
              }
            }
          }
          localStorage.setItem('swimLaneData', JSON.stringify(storedData));
        }
        $$('myBoard').attachEvent("onAfterCollapse", function(id: any) {
            updateSwimLaneCollapse(id, true);
        });
        $$('myBoard').attachEvent("onAfterExpand", function(id: any) {
            updateSwimLaneCollapse(id, false);
        });

        // Add new comments
        let commentsId = $(".webix_comments").attr('view_id');
        $$(commentsId).attachEvent("onAfterAdd", function(id: any, index: any) {
          var cardId = $$(commentsId).getParentView()._area.id;
          var comment = $$(commentsId).getItem(id);

          self.addComment(cardId, comment.text, JSON.stringify(mentionedUsers));
          webix.message("New comment is added at " + index + " position");
          mentionedUsers = [];
        });

        if (isKanBanEdit) {
          $$("$dataview1")?.attachEvent("onItemClick", function(id: any, e: any, node: any) {
            window.open('/sfc/servlet.shepherd/version/download/' + id);
            return false;
          });
          $$('$button2') ? $$('$button2').$view.style.visibility = 'hidden' : '';
        }

        $("#kanbanBoard").on("click", ".need-reply", function(e: any) {
          openCommentBox($(e.currentTarget)[0]);
        });
      });
    }

    addComment(cardId: any, commentText: any, mentionedUsers: any) {
      var param = {
        cardId: cardId,
        mentionedUsers: mentionedUsers,
        text: commentText,
      }
      commons.loader.show();
      commons.jqueryAjax(environment.BASE_URL + "project/kanban/comment", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status != 1) {
          commons.notification.show({
              type: "error",
              msg: res.message,
              timeout: 3000
          });
          return;
        }
      });
    }

    updateCardDetails(param: any) {
      commons.loader.show();
      commons.jqueryAjax(environment.BASE_URL + "project/kanban/updatecard", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status != 1) {
          commons.notification.show({
              type: "error",
              msg: res.message,
              timeout: 3000
          });
          return;
        }
      });
    }

    getChangeRequests() {
      let self = this;
      commons.loader.show();
      var param = {
        projectId: this.projectTabData.selProject
      }
      commons.jqueryAjax(environment.BASE_URL + "project/changerequests", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status != 1) {
          commons.notification.show({
              type: "error",
              msg: res.message,
              timeout: 3000
          });
          return;
        }
        self.projectTabData.crList = res.data.crList;
        $("#changeRequestModal").modal("show");
      });
    }

    hideChangeRequestModal() {
      $("#changeRequestModal").modal("hide");
    }

    showNewIssueModal() {
      this.initIssuetForm();
      this.getIssueData();
    }

    hideCreateIssue() {
      $("#createIssueModal").modal("hide");
    }

    getIssueData() {
      if (this.projectTabData.issueData.isDataLoaded) {
        $("#createIssueModal").modal("show");
        return;
      }
      var self = this;
      commons.loader.show();
      commons.jqueryAjax(environment.BASE_URL + "issue/requiredlist", {}, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.projectTabData.issueData = res.data;
          self.projectTabData.issueData.isDataLoaded = true;
          $("#createIssueModal").modal("show");
        }
      });
    }

    createProjectIssue() {
      this.loadingButtons.createIssueClicked = true;
      if (!this.issueForm.valid) {
        return;
      }
      var self = this;
      var param = self.issueForm.value;
      param['ProjectId'] = this.projectTabData.selProject;
      self.loadingButtons.isCreatingIssue = true;
      commons.jqueryAjax(environment.BASE_URL + "issue/create", { data: JSON.stringify(param) }, function(res: any) {
          self.loadingButtons.isCreatingIssue = false;
          if (res.status != 1) {
              commons.notification.show({
                  type: "error",
                  msg: res.message,
                  timeout: 3000
              });
              return;
          }
          $("#createIssueModal").modal("hide");
          commons.loader.show();
          self.getKanbanData();
      });
    }

    getProjectTaskGrid() {
      var self = this;
      var param = {
        projectId: this.projectTabData.selProject
      }
      commons.jqueryAjax(environment.BASE_URL + "project/overview/requiredlist", { data: JSON.stringify(param) }, function (res: any) {
        commons.loader.hide();
        if (res.status == 1) {
          self.projectTabData.overview = res.data;
          self.projectTabData.overview.dataLoaded = true;
          self.projectTabData.overview.ganttConfig = {zoomToFit: false, zoomToFitLabel: 'Fit'};
          self.setScaleConfig();
          self.initProjectTaskGantt();
        }
      });
    }

    initProjectTaskGantt() {
      var self = this;
      gantt.config.types["tcompleted"] = "type_id";
      gantt.config.readonly = true;
      gantt.locale.labels["type_tcompleted"] = "Completed Task";
      gantt.config.order_branch = "marker";
      //prevent moving to another sub-branch:
      gantt.attachEvent("onBeforeRowDragEnd", function(id: any, parent: any, tindex: any){
        var task = gantt.getTask(id);
        return task.parent == parent;
      });
      gantt.attachEvent("onTaskLoading", function (task: any) {
        if (task.deadline)
          task.deadline = gantt.date.parseDate(task.deadline, "xml_date");
        if (task.pStart)
          task.pStart = gantt.date.parseDate(task.pStart, "xml_date");
        return true;
      });
      gantt.attachEvent("onTaskDblClick", function(id: any, e: any) {
        return !gantt.getTask(id).isIssue;
      });
      gantt.attachEvent("onAfterSort", function(field: any, direction: any, parent: any) {
        self.saveFilterValue('config', 'sortBy', field);
        self.saveFilterValue('config', 'sortDirection', direction);
      });

      gantt.templates.task_class = function (start: any, end: any, task: any) {
        if (task.type == gantt.config.types.tcompleted) {
          return "completed_task";
        }
        if (task.isIssue) {
          return "project-issue";
        }
        return "";
      };
      gantt.templates.grid_row_class = function(start: any, end: any, task: any){
        if (task.isIssue) {
          return "project-issue-row";
        }
        return "";
      };

      function byId(list: any, id: any) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].key == id)
            return list[i].label || "";
        }
        return "";
      }

      gantt.locale.labels.column_fa = "Functional Area";
      gantt.locale.labels.column_owner = "Assign To";
      gantt.locale.labels.column_issue = "Issues";
      gantt.locale.labels.column_deadline = "Go Live Date";
      gantt.locale.labels.column_taskcategory = gantt.locale.labels.section_taskcategory = "Task Category";
      gantt.config.columns = [
        { name: "text", tree: true, min_width:250 , resize: true, template: function (item: any) {
          return item.text;
        }},
        { name: "owner", align: "center", resize: true, width: 90},
        { name: "start_date", align: "center", resize: true, width: 90 },
        { name: "duration", align: "center", resize: true, width: 78 },
        { name: "issue", align: "center", resize: true, width: 60 }
      ];

      gantt.config.resource_store = "resource";
      gantt.config.resource_property = "userId";
      gantt.config.order_branch = true;
      gantt.config.open_tree_initially = true;
      gantt.config.sort = true;

      gantt.templates.resource_cell_class = function(start_date: any, end_date: any, resource: any, tasks: any) {
        var css = [];
        css.push("resource_marker");
        if (tasks.length <= 1) {
        css.push("workday_ok");
        } else {
          css.push("workday_over");
        }
        return css.join(" ");
      };
      gantt.templates.resource_cell_value = function(start_date: any, end_date: any, resource: any, tasks: any){
        return "<div>" + tasks.length * 8 + "</div>";
      };

      self.setLayout();
      var resourcesStore = gantt.createDatastore({
        name: gantt.config.resource_store,
        type: "treeDatastore",
        initItem: function (item: any) {
          item.parent = item.parent || gantt.config.root_id;
          item[gantt.config.resource_property] = item.parent;
          item.open = true;
          return item;
        }
      });
      self.projectTabData.overview.ganttConfig.resourcesStore = resourcesStore;
      gantt.init("task_grid_gantt");
      self.showDeadline();

      resourcesStore.attachEvent("onParse", function(){
        var people: any = [];
        resourcesStore.eachItem(function(res: any){
          if(!resourcesStore.hasChild(res.id)){
            var copy = gantt.copy(res);
            copy.key = res.id;
            copy.label = res.text;
            people.push(copy);
          }
        });
        gantt.updateCollection("people", people);
      });

      self.applyConfigNumber(2, null);
      self.loadUserRecords();
      self.loadRecords(true);
    }

    setLayout() {
      gantt.config.layout = {
        css: "gantt_container",
        rows: [
        {
          cols: [
          {view: "grid", group:"grids", scrollY: "scrollVer"},
          {resizer: true, width: 1},
          {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
          {view: "scrollbar", id: "scrollVer", group:"vertical"}
          ],
            gravity:3
        },
        {resizer: true, width: 1},
        {view: "scrollbar", id: "scrollHor"}
        ]
      };

      var sortBy = this.getSavedFilterValue('config', 'sortBy');
      if (sortBy) {
        gantt.sort(sortBy, this.getSavedFilterValue('config', 'sortDirection'));
      }

      var scaleNumber = this.getSavedFilterValue('config', 'scaleNumber');
      if (scaleNumber != null && scaleNumber != undefined) {
        this.applyConfigNumber(scaleNumber, null);
      }
      gantt.init("task_grid_gantt");

      var zoomToFit = this.getSavedFilterValue('config', 'zoomToFit');
      this.showDeadline();
    }

    showDeadline() {
      try {
        gantt.addTaskLayer({
          renderer: {
            render: function draw_deadline(task: any) {
              if (task.deadline && !task.isIssue) {
                var el = document.createElement('div');
                if (task.glf) {
                  el.className = 'deadline';
                } else {
                  el.className = 'deadline not_live';
                }
                var sizes = gantt.getTaskPosition(task, task.deadline);

                el.style.left = sizes.left + 'px';
                el.style.top = sizes.top + 'px';

                el.setAttribute('title', gantt.templates.task_date(task.deadline));
                return el;
              }
              return false;
            },
            // define getRectangle in order to hook layer with the smart rendering
            getRectangle: function(task: any, view: any) {
              if(task.deadline){
                return gantt.getTaskPosition(task, task.deadline);
              }
              return null;
            }
          }
        });
        gantt.refreshData();
      } catch (ex) {console.log(ex)}
    }

    loadUserRecords() {
      let data = this.projectTabData.overview;
      gantt.serverList("user", data.user_data);
      gantt.serverList("taskcategory", data.task_category_data);

      gantt.locale.labels.section_taskworkstart = "Work Start";
      gantt.locale.labels.section_taskworkend = "Work End";
      gantt.locale.labels.section_taskplannedstart = "Planned Start";
      gantt.locale.labels.section_taskdatedue = "Milestone Date";
      gantt.locale.labels.section_taskassignedto = "Assigned To";
      gantt.locale.labels.section_taskroleassignment = "Project Role Assignment";
      gantt.locale.labels.section_taskprojectrole = "Project Role";
    }

    loadRecords(zoomtoFit: any) {
      let self = this;
      let tasks_data = self.parseObjectsV2(this.projectTabData.overview.grid_data);
      gantt.clearAll();
      var date_to_str = gantt.date.date_to_str(gantt.config.task_date);
      var today = new Date();
      gantt.addMarker({
        start_date: today,
        css: "today",
        text: "Today",
        title: "Today: " + date_to_str(today)
      });
      gantt.parse(tasks_data);
      if (zoomtoFit) {
        self.zoomToFit();
      }

      var user_data = this.projectTabData.overview.user_data;
      var resList: any = [];
      user_data.forEach(function(object: any) {
        if (object.key == '') {
          return;
        }
        var res: any = {};
        res['id'] = object.key;
        res['text'] = object.label;
        resList.push(res);
      });
      var resourcesStore = this.projectTabData.overview.ganttConfig.resourcesStore;
      resourcesStore.parse(resList);
    }

    parseObjectsV2(objects: any) {
      let self = this;
      let data: any = [];
      let links: any = [];
      var allProjectLoaded = true;
      var openNode: any = true;
      objects.forEach(function(object: any) {
        if (object.Configuration__c) {
          var projectItem = object.Configuration__c;
          var ganttItem = self.parseObjectItem(projectItem, projectItem.Id, 'Project', object.networkId, object.date);

          ganttItem.open = openNode;
          if(ganttItem.hasOwnProperty("start_date") && ganttItem.hasOwnProperty("duration")) {
            if (ganttItem.type != undefined) {
              ganttItem.type = gantt.config.types.project;
            }
            if (ganttItem.completed == true) {
              ganttItem.type = gantt.config.types.tcompleted;
            }
            //task
            data.push(ganttItem);
          } else {
            //link
            links.push(ganttItem);
          }

          if (projectItem.AcctSeed__Project_Tasks__r && projectItem.AcctSeed__Project_Tasks__r.records.length > 0) {
            projectItem.AcctSeed__Project_Tasks__r.records.forEach(function(task: any) {
              ganttItem = self.parseObjectItem(task, projectItem.Id, 'ProjectTask', object.networkId, object.date);

              ganttItem.open = openNode;
              if(ganttItem.hasOwnProperty("start_date") && ganttItem.hasOwnProperty("duration")) {
                if (ganttItem.type != undefined) {
                  ganttItem.type = gantt.config.types.project;
                }
                if (ganttItem.completed == true) {
                  ganttItem.type = gantt.config.types.tcompleted;
                }
                //task
                data.push(ganttItem);
              } else {
                //link
                links.push(ganttItem);
              }
            });
          }
          if (projectItem.Project_Issues1__r && projectItem.Project_Issues1__r.records.length > 0) {
            projectItem.Project_Issues1__r.records.forEach(function(issue: any) {
              ganttItem = self.parseObjectItem(issue, projectItem.Id, 'ProjectIssue', object.networkId, object.date);

              ganttItem.open = openNode;
              if (ganttItem.hasOwnProperty("start_date") && ganttItem.hasOwnProperty("duration")) {
                if (ganttItem.type != undefined) {
                  ganttItem.type = gantt.config.types.project;
                }
                if (ganttItem.completed == true) {
                  ganttItem.type = gantt.config.types.tcompleted;
                }
                //task
                data.push(ganttItem);
              } else {
                //link
                links.push(ganttItem);
              }
            });
          }
        }
      });

      return {
        data: data,
        links: links
      };
    }

    parseObjectItem(dataNode: any, projId: any, type: any, networkId: any, todayDate: any) {
      var returnObj: any = {};
      var dateStr;

      if (type == 'Project') {
        returnObj.id = dataNode.Id;
        returnObj.text = dataNode.Name;
        returnObj.start_date = '';
        returnObj.deadline = dataNode.Go_Live_Date__c != null ? this.formatDate(dataNode.Go_Live_Date__c, 'DD-MM-YYYY') : '';
        returnObj.glf = dataNode.Go_Live_Date_Firm__c;
        returnObj.duration = '';
        returnObj.user = dataNode.Primary_Implementer__r ? dataNode.Primary_Implementer__r.Name : '';
        returnObj.userId = dataNode.Primary_Implementer__c;
        returnObj.owner = dataNode.Primary_Implementer__r ? dataNode.Primary_Implementer__r.Name : '';
        returnObj.type = 'gantt.config.types.project';
        returnObj.readonly = true;
        returnObj.issue = dataNode.Open_Project_Issue__c == null ? 0 : dataNode.Open_Project_Issue__c;
        returnObj.isIssue = false;
        returnObj.progress = dataNode.Percent_Complete__c == null ? 0 : (dataNode.Percent_Complete__c / 100);
        returnObj.isopen = true;
        returnObj.status = dataNode.AcctSeed__Status__c;
        returnObj.sn = dataNode.Status_Notes__c;
        returnObj.stage = dataNode.Project_Stage__c;
        returnObj.rowType = type;
      } else if (type == 'ProjectTask') {
        returnObj.id = dataNode.Id;
        returnObj.parent = projId;
        if (dataNode.Parent_Task__c != null) {
          returnObj.parent = dataNode.Parent_Task__c;
        }
        returnObj.text = dataNode.Name;
        dateStr = !dataNode.AcctSeed__Start_Date__c ? todayDate : dataNode.AcctSeed__Start_Date__c;
        returnObj.start_date = this.formatDate(dateStr, 'DD-MM-YYYY');
        dateStr = dataNode.AcctSeed__End_Date__c == null ? todayDate : dataNode.AcctSeed__End_Date__c;
        returnObj.end_date = this.formatDate(dateStr, 'DD-MM-YYYY');
        returnObj.deadline = dataNode.Due_Date__c != null ? this.formatDate(dataNode.Due_Date__c, 'DD-MM-YYYY') : '';
        returnObj.duration = dataNode.AcctSeed__Duration__c ? dataNode.AcctSeed__Duration__c.toString() : '';
        returnObj.user = dataNode.Assigned_To_User__r ? dataNode.Assigned_To_User__r.Name : '';
        returnObj.owner = dataNode.Assigned_To_User__r ? dataNode.Assigned_To_User__r.Name : '';
        returnObj.userId = dataNode.Assigned_To_User__c;
        returnObj.progress = dataNode.Percent_Complete__c == null ? 0 : (dataNode.Percent_Complete__c / 100);
        returnObj.completed = dataNode.AcctSeed__Status__c == 'Complete';
        returnObj.issue = dataNode.Open_Project_Issue__c == null ? 0 : dataNode.Open_Project_Issue__c;
        returnObj.isIssue = false;
        returnObj.isopen = false;
        returnObj.pr = dataNode.Project_Role__r ? dataNode.Project_Role__r.Name : '';
        returnObj.assignTo = dataNode.Assigned_To_User__c;
        returnObj.pStart = dataNode.Planned_Start__c != null ? this.formatDate(dataNode.Planned_Start__c, 'DD-MM-YYYY') : '';
        returnObj.prae = dataNode.Project_Role_Assignment__r && dataNode.Project_Role_Assignment__r.Employee__r ?
                    dataNode.Project_Role_Assignment__r.Employee__r.Name : '';
        returnObj.rowType = type;
        returnObj.fa = dataNode.Functional_Area__c;
      } else if (type == 'ProjectIssue') {
        returnObj.id = dataNode.Id;
        returnObj.parent = projId;
        if (dataNode.AS_Project_Task__c != null) {
          returnObj.parent = dataNode.AS_Project_Task__c;
        }
        returnObj.text = dataNode.Issue_Title__c;
        dateStr = dataNode.Date__c == null ? todayDate : dataNode.Date__c;
        returnObj.start_date = this.formatDate(dateStr, 'DD-MM-YYYY');
        returnObj.end_date = dataNode.Due_Date__c != null ? this.formatDate(dataNode.Due_Date__c, 'DD-MM-YYYY') : '';
        returnObj.deadline = dataNode.Due_Date__c != null ? this.formatDate(dataNode.Due_Date__c, 'DD-MM-YYYY') : '';
        returnObj.duration = dataNode.Duration__c ? dataNode.Duration__c.toString() : '';
        returnObj.completed = dataNode.Status__c == 'Complete';
        returnObj.user = dataNode.Assigned_To__r ? dataNode.Assigned_To__r.Name : '';
        returnObj.owner = dataNode.Assigned_To__r ? dataNode.Assigned_To__r.Name : '';
        returnObj.userId = dataNode.Assigned_To__c;
        returnObj.progress = dataNode.Percent_Complete__c == null ? 0 : (dataNode.Percent_Complete__c / 100);
        returnObj.assignTo = dataNode.Assigned_To__c;
        returnObj.isIssue = true;
        returnObj.isopen = false;
        returnObj.issue = 0;
        returnObj.rowType = type;
        returnObj.fa = dataNode.Functional_Area__c;
      }
      return returnObj;
    }

    zoomToFit() {
      let self = this;
      var project = gantt.getSubtaskDates(),
      areaWidth = gantt.$task.offsetWidth;
      var scaleConfigs = self.projectTabData.overview.ganttConfig.scaleConfigs;
      for (var i = 0; i < scaleConfigs.length; i++) {
        var columnCount = self.getUnitsBetween(project.start_date, project.end_date, scaleConfigs[i].scales[0].subscale_unit, scaleConfigs[i].scales[0].step);
        if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
          break;
        }
      }
      if (i == scaleConfigs.length) {
        i--;
      }
      self.applyConfigNumber(i, project);
      gantt.render();
    }

    applyConfigNumber(num: any, dates: any) {
      var scaleConfigs = this.projectTabData.overview.ganttConfig.scaleConfigs;
      if (num < 0 || num >= scaleConfigs.length) {
        return;
      }
      this.applyConfig(scaleConfigs[num], dates);
      gantt.render();
      this.projectTabData.overview.ganttConfig.scaleNumber = num;
    }

    applyConfig(config: any, dates: any) {
      gantt.config.scales = config.scales;
      if (dates && dates.start_date && dates.end_date) {
        gantt.config.start_date = gantt.date.add(dates.start_date, -1, config.scales[0].subscale_unit);
        gantt.config.end_date = gantt.date.add(gantt.date[config.scales[0].subscale_unit + "_start"](dates.end_date), 2, config.scales[0].subscale_unit);
      } else {
        gantt.config.start_date = gantt.config.end_date = null;
      }
    }

    getUnitsBetween(from: any, to: any, unit: any, step: any) {
      var start = new Date(from), end = new Date(to);
      var units = 0;
      while (start.valueOf() < end.valueOf()) {
      units++;
        start = gantt.date.add(start, step, unit);
      }
      return units;
    }

    getSavedFilterValue(searchKey: any, fieldName: any) {
      var taskGridData = localStorage.getItem('taskGridData');
      if (!taskGridData) {
        return;
      }
      taskGridData = taskGridData ? JSON.parse(taskGridData) : {};
      return taskGridData && taskGridData[searchKey] ? taskGridData[searchKey][fieldName] : '';
    }

    saveFilterValue(type: any, key: any, value: any) {
      var taskGridData: any = localStorage.getItem('taskGridData');
      taskGridData = taskGridData ? JSON.parse(taskGridData) : {};
      if (!taskGridData.hasOwnProperty(type)) {
        taskGridData[type] = {};
      }
      taskGridData[type][key] = value;
      localStorage.setItem('taskGridData', JSON.stringify(taskGridData));
    }

    setScaleConfig() {
      var scaleConfigs = [
        // minutes
        {
          scales: [
            {subscale_unit: "minute", unit: "hour", step: 1, format: "%H"},
            {unit: "minute", step: 1, format: "%H:%i"}
          ]
        },
        // hours
        {
          scales: [
            {subscale_unit: "hour", unit: "day", step: 1, format: "%j %M"},
            {unit: "hour", step: 1, format: "%H:%i"}
          ]
        },
        // days
        {
          scales: [
            {subscale_unit: "day", unit: "month", step: 1, format: "%F"},
            {unit: "day", step: 1, format: "%j"}
          ]
        },
        // weeks
        {
          scales: [
            {subscale_unit: "week", unit: "month", step: 1, date: "%F"},
            {
              unit: "week", step: 1, template: function (date: any) {
                var dateToStr = gantt.date.date_to_str("%d %M");
                var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ]
        },
        // months
        {
          scales: [
            {subscale_unit: "month", unit: "year", step: 1, format: "%Y"},
            {unit: "month", step: 1, format: "%M"}
          ]
        },
        // quarters
        {
          scales: [
            {subscale_unit: "month", unit: "year", step: 3, format: "%Y"},
            {
              unit: "month", step: 3, template: function (date: any) {
                var dateToStr = gantt.date.date_to_str("%M");
                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ]
        },
        // years
        {
          scales: [
            {subscale_unit: "year", unit: "year", step: 1, date: "%Y"},
            {
              unit: "year", step: 5, template: function (date: any) {
                var dateToStr = gantt.date.date_to_str("%Y");
                var endDate = gantt.date.add(gantt.date.add(date, 5, "year"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ]
        },
        // decades
        {
          scales: [
            {
              subscale_unit: "year", unit: "year", step: 10, template: function (date: any) {
                var dateToStr = gantt.date.date_to_str("%Y");
                var endDate = gantt.date.add(gantt.date.add(date, 10, "year"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            },
            {
              unit: "year", step: 100, template: function (date: any) {
                var dateToStr = gantt.date.date_to_str("%Y");
                var endDate = gantt.date.add(gantt.date.add(date, 100, "year"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ]
        }
      ];
      this.projectTabData.overview.ganttConfig.scaleConfigs = scaleConfigs;
    }

    onZoomToFit() {
      var zoomToFit = this.projectTabData.overview.ganttConfig.zoomToFit == false;
      this.projectTabData.overview.ganttConfig.zoomToFit = zoomToFit;
      if (zoomToFit) {
        this.projectTabData.overview.ganttConfig.zoomToFitLabel = 'Set default Scale';
        //Saving previous scale state for future restore
        var config = gantt.config;
        var cachedSettings: any = {};
        cachedSettings.scales = config.scales;
        cachedSettings.start_date = config.start_date;
        cachedSettings.end_date = config.end_date;
        cachedSettings.number = this.projectTabData.overview.ganttConfig.scaleNumber;

        this.projectTabData.overview.ganttConfig.cachedSettings = cachedSettings;
        this.zoomToFit();
      } else {
        this.projectTabData.overview.ganttConfig.zoomToFitLabel = 'Fit';
        var cachedSettings = this.projectTabData.overview.ganttConfig.cachedSettings;
        this.applyConfig(cachedSettings, null);
        this.projectTabData.overview.ganttConfig.scaleNumber = cachedSettings.number;
        gantt.render();
      }
      this.saveFilterValue('config', 'zoomToFit', !zoomToFit);
    }

    onZoomOut() {
      var number = this.projectTabData.overview.ganttConfig.scaleNumber;
      this.saveFilterValue('config', 'scaleNumber', (number + 1));
      this.applyConfigNumber(number + 1, null);
    }

    onZoomIn() {
      var number = this.projectTabData.overview.ganttConfig.scaleNumber;
      this.saveFilterValue('config', 'scaleNumber', (number - 1));
      this.applyConfigNumber(number - 1, null);
    }

    formatDate(date: string, format: string) {
      return moment.utc(date).format(format);
    }
}