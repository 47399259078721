<app-header></app-header>
<div class="m-3">
    <div class="row">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <img src="https://via.placeholder.com/100" alt="User avatar" class="rounded-circle" />
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="mb-1">{{profileData.CommunityNickname}}</h5>
                                    {{profileData.BadgeText}}
                                </div>
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-outline-primary float-end"
                                        (click)="showEditProfile()">
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2 border-bottom">
                                <div class="col-md-6">
                                    <div class="form-text">Name</div>
                                    <label>{{profileData.Name}}</label>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-text">Manager</div>
                                    <label>{{profileData['Manager.Name']}}</label>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-md-6">
                                    <div class="form-text">Title</div>
                                    <label>{{profileData.Title}}</label>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-text">Company Name</div>
                                    <label>{{profileData.CompanyName}}</label>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-md-6">
                                    <div class="form-text">Email</div>
                                    <label>{{profileData.Email}}</label>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-text">Phone</div>
                                    <label>{{profileData.Phone}}</label>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-md-6">
                                    <div class="form-text">Address</div>
                                    <div *ngIf="profileData.Address && profileData.Address.street">
                                        <p class="mb-0">{{profileData.Address.street}}</p>
                                        <p class="mb-0">{{profileData.Address.city}}, {{profileData.Address.state}}
                                            {{profileData.Address.postalCode}}</p>
                                        <p class="mb-0">{{profileData.Address.country}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-text">Mobile</div>
                                    <label>{{profileData.MobilePhone}}</label>
                                </div>
                            </div>
                            <div class="row border-bottom">
                                <div class="col-md-12">
                                    <div class="form-text">About Me</div>
                                    <label>{{profileData.AboutMe}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body mx-2">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>Influence</h5>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-md-4">
                            <div class="form-text">Following</div>
                            <label>0</label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-text">Followers</div>
                            <label>0</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-text">Posts</div>
                            <label>0</label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-text">Comments</div>
                            <label>0</label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-text">Likes Received</div>
                            <label>0</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-3">
            <ul class="nav nav-tabs" id="profileTabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="nav-feed-tab" data-bs-toggle="tab" data-bs-target="#nav-feed" type="button" role="tab" aria-controls="nav-feed" aria-selected="false">FEED</button>
                  </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="nav-tickets-tab" data-bs-toggle="tab" data-bs-target="#nav-tickets" type="button" role="tab" aria-controls="nav-tickets" aria-selected="true">TICKETS</button>
                </li>
            </ul>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade" id="nav-feed" role="tabpanel" aria-labelledby="nav-feed-tab">
                    ...
                </div>
                <div class="tab-pane fade show active" id="nav-tickets" role="tabpanel" aria-labelledby="nav-tickets-tab">
                    <div class="list-group list-group-flush">
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col-6">
                                    <h5 class="mb-0 mt-2">My Cases</h5>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-link text-decoration-none float-end"
                                        (click)="showCreateCase()">Create Case</button>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="table-responsive" id="reportTable" *ngIf="caseList && caseList.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let case of caseList; let i = index">
                                            <td>{{i + 1}}</td>
                                            <td>{{case.Subject}}</td>
                                            <td>{{case.Description}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- update user profile -->
<div class="modal fade" id="editProfileModal" role="dialog" aria-labelledby="editProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editProfileModalLabel">Edit User</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideEditProfile()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="profileForm" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">First Name<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="FirstName" name="FirstName"
                                    value="{{editProfileData.FirstName}}">
                                <label *ngIf="loadingButtons.updateClicked && profileForm.get('FirstName')?.invalid"
                                    [ngClass]="'error'"> First Name is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Last Name<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="LastName" name="LastName"
                                    value="{{editProfileData.LastName}}">
                                <label *ngIf="loadingButtons.updateClicked && profileForm.get('LastName')?.invalid"
                                    [ngClass]="'error'"> Last Name is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Title</label>
                                <input type="text" class="form-control" formControlName="Title" name="Title"
                                    value="{{editProfileData.Title}}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Company Name</label>
                                <input type="text" class="form-control" formControlName="CompanyName" name="CompanyName"
                                    value="{{editProfileData.CompanyName}}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Email<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="Email" name="Email"
                                    value="{{editProfileData.Email}}">
                                <label *ngIf="loadingButtons.updateClicked && profileForm.get('Email')?.invalid"
                                    [ngClass]="'error'"> Email is required. </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Phone</label>
                                <input type="text" class="form-control" formControlName="Phone" name="Phone"
                                    value="{{editProfileData.Phone}}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Mobile</label>
                                <input type="text" class="form-control" formControlName="MobilePhone" name="Mobile"
                                    value="{{editProfileData.MobilePhone}}">
                            </div>
                        </div>
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Street</label>
                                <textarea type="text" class="form-control" formControlName="Street" name="Street"
                                    value="{{editProfileData.Address ? editProfileData.Address.street : ''}}"></textarea>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="col-form-label">City</label>
                                <input type="text" class="form-control" formControlName="City" name="City"
                                    value="{{editProfileData.Address ? editProfileData.Address.city : ''}}">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="col-form-label">State/Province</label>
                                <input type="text" class="form-control" formControlName="State" name="State"
                                    value="{{editProfileData.Address ? editProfileData.Address.state : ''}}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Zip/Postal Code</label>
                                <input type="text" class="form-control" formControlName="PostalCode" name="PostalCode"
                                    value="{{editProfileData.Address ? editProfileData.Address.postalCode : ''}}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">Country</label>
                                <input type="text" class="form-control" formControlName="Country" name="Country"
                                    value="{{editProfileData.Address ? editProfileData.Address.country : ''}}">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="col-form-label">About Me</label>
                                <textarea type="text" class="form-control" formControlName="AboutMe" name="AboutMe"
                                    value="{{editProfileData.AboutMe}}"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideEditProfile()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="updateProfile()"
                    [attr.disabled]="loadingButtons.isUpdating ? 'disabled' : null">
                    <span *ngIf="loadingButtons.isUpdating">
                        <i class='spinner-border spinner-border-sm'></i> Updating...
                    </span>
                    <span *ngIf="!loadingButtons.isUpdating">Update</span>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- create case -->
<div class="modal fade" id="createCaseModal" role="dialog" aria-labelledby="createCaseModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createCaseModalLabel">Create New Case</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="hideCreateCase()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="caseForm" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Subject<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" formControlName="Subject" name="Subject">
                                <label *ngIf="loadingButtons.createCaseClicked && caseForm.get('Subject')?.invalid"
                                    [ngClass]="'error'"> Subject is required. </label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Description<span class="text-danger">*</span></label>
                                <textarea type="text" class="form-control" formControlName="Description"
                                    name="Description"></textarea>
                                <label *ngIf="loadingButtons.createCaseClicked && caseForm.get('Description')?.invalid"
                                    [ngClass]="'error'"> Description is required. </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                    (click)="hideCreateCase()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="createCase()"
                    [attr.disabled]="loadingButtons.isCreatingCase ? 'disabled' : null">
                    <span *ngIf="loadingButtons.isCreatingCase">
                        <i class='spinner-border spinner-border-sm'></i> Saving...
                    </span>
                    <span *ngIf="!loadingButtons.isCreatingCase">Save</span>
                </button>
            </div>
        </div>
    </div>
</div>